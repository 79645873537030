import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { faArrowDownWideShort, faCalendar, faCircleUser, faListUl, faPaperclip, faSearch, faTableCells, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddPeopleModal from '../AddPeopleModal/AddPeopleModal';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';

import { Context } from "../../../../../utils/context";

const FilterTab = ({
    priority,
    assign_to,
    task_status,
    setPriority,
    setAssignTo,
    setTaskStatus,
}) => {

    const { getData, postData, IMG_URL, deleteData, Select2Data, Per_Page_Dropdown, userdata } = useContext(Context);

    const [modalShow, setModalShow] = useState(false);

    const [taskmodalShow, setTaskModalShow] = React.useState(false);

    const [showFilter, setShowFilter] = React.useState(false);
    const filterRef = useRef(null);

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    // Handle click outside of the filter
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowFilter(false);
            }
        };

        // Attach the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterRef]);
    const [projects, setProjects] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [taskStatuses, setTaskStatuses] = useState([]);
    const [users, setUsers] = useState([]);

    const GetAllProjects = async () => {
        const res = await getData("/project_management/allproject");
        if (res?.success) {
            setProjects(await Select2Data(res?.data, "project_id"))
        }
    };

    const GetAllPriorities = async () => {
        const res = await getData("/masters/all-priority-status");
        if (res?.success) {
            setPriorities(await Select2Data(res?.data, "priority_id"))
        }
    };

    const GetAllEmployees = async () => {
        const res = await getData("/masters/allemployees");
        if (res?.success) {
            setUsers(res?.data);
        }
    };

    const GetAllTAskStatus = async () => {
        const res = await getData("/masters/all-task-status");
        if (res?.success) {
            setTaskStatuses(await Select2Data(res?.data, "task_status_id"))
        }
    };
    useEffect(() => {
        GetAllEmployees();
        GetAllProjects();
        GetAllPriorities();
        GetAllTAskStatus();
    }, []);

    const [selectedPriority, setSelectedPriority] = useState([]);

    const handlePriorityChange = (value) => {
        setSelectedPriority(prevSelected => {
            let newSelection;

            if (!Array.isArray(prevSelected)) {
                newSelection = [value];
            } else {
                newSelection = prevSelected.includes(value)
                    ? prevSelected.filter(item => item !== value)
                    : [...prevSelected, value];
            }

            // Join selected priorities into a string and set into priority state
            const joinedPriorities = newSelection.join(",");
            setPriority(joinedPriorities);

            return newSelection;
        });
    };

    const [selectedTS, setSelectedTS] = useState([]);
    const handleStatusChange = (value) => {
        setSelectedTS(prevSelected => {
            let newSelection;

            if (!Array.isArray(prevSelected)) {
                newSelection = [value];
            } else {
                newSelection = prevSelected.includes(value)
                    ? prevSelected.filter(item => item !== value)
                    : [...prevSelected, value];
            }

            // Join selected priorities into a string and set into priority state
            const joinedPriorities = newSelection.join(",");
            setTaskStatus(joinedPriorities);

            return newSelection;
        });
    };
    console.log("userdata", userdata);

    return (
        <section>
            <div className='board-subbbbb'>
                <Row>

                    {/* <Col lg={3}>
                        <Form>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <InputGroup>
                                    <Form.Control type="text" placeholder="Search Board" />
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col lg={1}>
                        <FontAwesomeIcon icon={faUserPlus} className='plususername' onClick={() => setModalShow(true)} />
                        <AddPeopleModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </Col> */}
                    <Col xl={8} lg={10} sm={12} xs={12} >
                        <div className='mainnnnnn-filterrrr'>
                            <div className='text-end'>
                                <button className='filter-btnnn' onClick={toggleFilter}>
                                    <FontAwesomeIcon icon={faArrowDownWideShort} /> Filter
                                </button>
                            </div>
                            {showFilter && (
                                <div ref={filterRef} className='main-class-board'>
                                    <div className='dark-brown'>
                                        <p className='todo-task-txtt'>Filter</p>
                                    </div>
                                    <div className='fliter-sub'>
                                        <div className='d-flex mt-3'>
                                            <FontAwesomeIcon icon={faCircleUser} className={assign_to ? 'yellow-bggg' : 'me-2'}
                                                onClick={() => setAssignTo(prevAssignTo => prevAssignTo === userdata?.id ? null : userdata?.id)}
                                            />
                                            <p className='txtttt-demo'>Assigned To me</p>
                                        </div>
                                        <div className='d-flex'>
                                            <FontAwesomeIcon icon={faCalendar} className='me-2' />
                                            <p className='txtttt-demo'>Due This Week</p>
                                        </div>
                                        <div className='light-brown mb-3'></div>
                                        <p className='filter-title'>Assignee</p>

                                        <div className='d-flex'>
                                            {users && users?.map((user) =>
                                                <img
                                                    className={assign_to === user?.id ? 'yellow-bggg' : ''}
                                                    src={IMG_URL + user?.image}
                                                    style={{ width: "40px", height: "40px", borderRadius: "50px" }}
                                                    alt={user?.name}
                                                    onClick={() => setAssignTo(user?.id)}
                                                />
                                            )}
                                            {/* <p className='green-bggg'>MZ</p>
                                            <p className='pink-bggg'>NK</p>
                                            <p className='yellow-bggg'>AK</p>
                                            <p className='black-bggg'>AJ</p>
                                            <p className='blue-bggg'>MZ</p> */}
                                        </div>
                                        {/* <p className='filter-title mt-3'>CATEGORY</p>
                                        <div>
                                            <Form>
                                                <div className='borderrrr'>
                                                    <Form.Check
                                                        type='checkbox'
                                                        label='No Category'
                                                    />
                                                </div>
                                            </Form>
                                        </div> */}
                                        <p className='filter-title mt-3'>PRIORITY</p>
                                        <div>
                                            <Form>
                                                {priorities && priorities.map((pri) => (
                                                    <div className='borderrrr mt-2' key={pri.value}>
                                                        <Form.Check
                                                            type='checkbox'
                                                            label={pri.label}
                                                            checked={selectedPriority.includes(pri.value)}
                                                            onChange={() => handlePriorityChange(pri.value)}
                                                        />
                                                    </div>
                                                ))}
                                            </Form>
                                        </div>
                                        {/* <p className='filter-title mt-3'>Label</p>
                                        <div className='borderrrr'>
                                            <span className='txtttt-demo'>No Label</span>
                                        </div> */}
                                        <p className='filter-title mt-3'>STATUS</p>
                                        <div>
                                            {taskStatuses && taskStatuses?.map((taskStatus) =>
                                                <div className='borderrrr me-2 mt-2'>
                                                    <Form.Check
                                                        type='checkbox'
                                                        label={taskStatus?.label}
                                                        checked={selectedTS.includes(taskStatus.value)}
                                                        className='green-txttt'
                                                        onChange={() => handleStatusChange(taskStatus.value)}
                                                    />
                                                </div>
                                            )}

                                            {/* <div className='borderrrr me-2'>
                                                <Form.Check
                                                    type='checkbox'
                                                    label='In Progress'
                                                    className='inprogress-txttt'
                                                />
                                            </div>
                                            <div className='borderrrr'>
                                                <Form.Check
                                                    type='checkbox'
                                                    label='To Do'
                                                    className='todo-txtttt'
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default FilterTab