import React from "react";
import "./Notification_Card.css";

function Notification_Card({ notificationData, IMG_URL }) {
  return (
    <section className="Notification_Card">
      {notificationData?.map((noti) =>
        <div className="Notification_Card_holder">
          <div className="row card-content-holder">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-3 ">
              <div className="img-holder">
                <img
                  className="Notification_Card-img"
                  src={IMG_URL + noti?.updated_by?.image}
                  alt="..."
                />
              </div>
            </div>

            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-8">
              <div className="text-holder">
                <h3>{noti?.message}</h3>
                <p>{new Date(noti?.createdAt).toDateString()}</p>
              </div>
            </div>


          </div>
        </div>
      )}

    </section>
  );
}

export default Notification_Card;
