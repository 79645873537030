import React, { useContext } from "react";
import { useState, useEffect } from "react";
import '../LoginPage/LoginPage.css';
import { Link } from 'react-router-dom';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import Cookies from "js-cookie";

const LoginPage = () => {

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setError,
        watch,
        getValues,
        reset,
    } = useForm();

    const navigate = useNavigate();

    const { postData, signin, setSignin, usertype, setUsertype } = useContext(Context);

    const [passwordVisible, setPasswordVisible] = useState(false);

    const onSubmit = async (data) => {
        try {
            const response = await postData("/login", data);

            if (response.success) {
                setSignin(response?.success);
                setUsertype(response?.data?.role);

                Cookies.set("projectmanagement_security", response.data?.token, {
                    expires: 2,
                });
                navigate("/");
            } else {
                // setShowErrorModal(response);
                setError("password", {
                    type: "manual",
                    message: response?.message
                });
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <>
            <section className="Login">
                <div className="container-fluid p-0">
                    <div className='card text-center login-card p-5'>
                        <div className='text-holder-login'>
                            <h2>Login</h2>
                            <p>Hey, Enter your details to sign in to your account</p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            placeholder="Client Email"
                                            className={classNames("", {
                                                "is-invalid": errors?.email,
                                                "is-valid": !errors?.email && getValues("email"),
                                            })}
                                            {...register("email", {
                                                required: "Client Email is required",
                                                pattern: {
                                                    value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                                    message: "Invalid E-Mail Address",
                                                },
                                            })}
                                        />
                                    </InputGroup>
                                    {errors.email && (
                                        <span className="text-danger">
                                            {errors.email.message}
                                        </span>
                                    )}
                                </div>
                                <div className="input-group mb-3">
                                    {/* <input
                                        type={passwordVisible ? "text" : "password"}
                                        className="form-control"
                                        placeholder="Password"
                                        aria-label="Password"
                                        aria-describedby="basic-addon2"
                                    /> */}
                                    <InputGroup>
                                        <Form.Control
                                            type={passwordVisible ? "text" : "password"}
                                            name="password"
                                            placeholder="Password"
                                            className={classNames("", {
                                                "is-invalid": errors?.password,
                                                "is-valid": !errors?.password && getValues("password"),
                                            })}
                                            {...register("password", {
                                                required: "Password is required",
                                            })}
                                        />
                                        <span
                                            className="input-group-text hide-name"
                                            id="basic-addon2"
                                            onClick={togglePasswordVisibility}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {passwordVisible ? <EyeSlash /> : <Eye />}
                                        </span>
                                    </InputGroup>

                                    {errors.password && (
                                        <span className="text-danger">
                                            {errors.password.message}
                                        </span>
                                    )}
                                </div>

                                <button
                                    type="submit"
                                    className="btn sign-in-button text-center"
                                >
                                    Sign in
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LoginPage;
