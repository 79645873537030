import React, { useContext, useState } from "react";
import { Col, Container, Form, Row, Table, Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import Attachments from '../../Animation/empty-attachements/Attachments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Context } from "../../../../../utils/context";

const options = [
  { value: '', label: 'All' },
  { value: 'pdf', label: 'PDFs' },
  { value: 'image', label: 'Images' },
  { value: 'other', label: 'Other' },
];

const AttachementsTab = ({ project_id, tasks }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { IMG_URL } = useContext(Context);
  const [currentFile, setCurrentFile] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [fileType, setFileType] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  const handleShowFile = (fileUrl, type) => {
    setCurrentFile(fileUrl);
    setFileType(type);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentFile('');
    setFileType('');
  };

  const handleDownloadFile = (fileUrl) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileUrl.split('/').pop(); // Sets the file name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [searchQuery, setSearchQuery] = useState('');
  const [fileTypeFilter, setFileTypeFilter] = useState(null);

  const filteredTasks = tasks?.data?.data?.filter((task) => {
    const attachmentUrl = IMG_URL + task?.attachment;
    const fileExtension = task?.attachment.split('.').pop().toLowerCase();
    const createdAtDate = new Date(task?.createdAt);
    const selectedDateObj = new Date(selectedDate);

    // Check if the task name or attachment matches the search query
    const matchesSearchQuery = task?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task?.attachment.toLowerCase().includes(searchQuery.toLowerCase());

    // Check if the attachment matches the file type filter
    const matchesFileType = !fileTypeFilter ||
      (fileTypeFilter === 'pdf' && fileExtension === 'pdf') ||
      (fileTypeFilter === 'image' && ['jpeg', 'png'].includes(fileExtension)) ||
      (fileTypeFilter === 'other' && !['jpeg', 'png', 'pdf'].includes(fileExtension));

    // Check if the createdAt date matches the selected date
    const matchesDate = !selectedDate || createdAtDate.toDateString() === selectedDateObj.toDateString();

    return matchesSearchQuery && matchesFileType && matchesDate;
  });


  return (
    <section>
      <Container>
        {tasks?.data?.data?.length > 0 ? (
          <>
            <Row>
              <Col lg={3}>
                <Form>
                  <Form.Group className="mb-3" controlId="searchAttachments">
                    <Form.Control
                      type="text"
                      placeholder="Search attachments"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col lg={3}>
                <Select
                  defaultValue={fileTypeFilter ? options.find(option => option.value === fileTypeFilter) : null}
                  onChange={(selectedOption) => {
                    setFileTypeFilter(selectedOption ? selectedOption.value : null);
                  }}
                  options={options}
                  placeholder="Select file type"
                />
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3" controlId="uploadFile">
                  <Form.Control type="file" placeholder="Upload attachments" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3" controlId="filterDate">
                  <Form.Control
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* Full Attachments */}
            <div className='mt-4 text-center'>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr. No</th>
                    <th>Task Name</th>
                    <th>Attachments</th>
                    <th>View</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTasks.map((task, index) => {
                    const attachmentUrl = IMG_URL + task?.attachment;
                    const fileExtension = task?.attachment.split('.').pop();
                    const isImage = ['jpeg', 'png'].includes(fileExtension);
                    const isPdf = fileExtension === 'pdf';

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{task?.name}</td>
                        <td>
                          {isImage ? (
                            <img
                              src={attachmentUrl}
                              style={{ width: "50px", height: "50px" }}
                              className="banner-img"
                              alt="Attachment"
                            />
                          ) : isPdf ? (
                            <img
                              src="/pdf-icon.png" // Use an appropriate PDF icon
                              style={{ width: "50px", height: "50px" }}
                              className="banner-img"
                              alt="PDF"
                            />
                          ) : (
                            <span>File</span> // Handle other types if necessary
                          )}
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={() => handleShowFile(attachmentUrl, fileExtension)}
                            style={{ cursor: 'pointer' }}
                          />
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon={faDownload}
                            onClick={() => handleDownloadFile(attachmentUrl)}
                            style={{ cursor: 'pointer' }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <Modal show={showModal} onHide={handleClose} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Preview</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {fileType === 'pdf' ? (
                  <iframe
                    src={currentFile}
                    style={{ width: '100%', height: '500px' }}
                    frameBorder="0"
                    title="PDF Preview"
                  />
                ) : (
                  <img src={currentFile} alt="Preview" style={{ width: '100%' }} />
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          <>
            {/* Empty attachments */}
            <div className='attchements-txtt'>
              <Attachments />
              <h6>You don't have any attachments yet</h6>
              <p>This is where you'll find any attachments added to issues in this project</p>
            </div>
          </>
        )}
      </Container>
    </section>
  );
};

export default AttachementsTab;
