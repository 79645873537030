import React from "react";
import circle from "../icons/circle.png";
import CelebrationAnimation from "../CommonSuccessModal/CelebrationAnimation/CelebrationAnimation";
import './ModalSave.css'
import ErrorAnimation from "./ErrorAnimation/ErrorAnimation";

const ModalSave = (props) => {
  return (
    <div className="schedule-modal">
      <div
        className={`modal fade ${props.showErrorModal ? "show" : ""}`}
        style={{ display: props.showErrorModal ? "block" : "none" }}
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              {/* {props.showErrorModal ? (
                <div className="text-center">
                  <ErrorAnimation />
                </div>
              ) : ( */}
              <div className="text-center">
                <CelebrationAnimation />
              </div>
              {/* )} */}
              <h1 className="add-success text-center">{props.message}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ModalSave;
