import React, { useContext } from "react";
import { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import ProjectDeleteModal from './ProjectAddModal/ProjectDeleteModal';
import ProjectEditModal from './ProjectAddModal/ProjectEditModal';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useForm, Controller } from "react-hook-form";

import { Context } from "../../../utils/context";

// teamData
export const teamData = [
  {
    id: 1,
    project: 'Hospital Administration',
    projectid: 'PRO-ID000',
    leader: '/assets/Image/photos/avatar-14.jpg',
    members: [
      '/assets/Image/photos/avatar-14.jpg',
      '/assets/Image/photos/avatar-18.jpg',
      '/assets/Image/photos/avatar-19.jpg',
    ],
    deadline: '12-02-2024',
    priority: 'Medium',
    budget: '4546469',
    status: 'Inactive',
    action: 'edit',
    viewdashboard: 'View Dashboard'
  },
  {
    id: 2,
    project: 'Hospital Administration',
    projectid: 'PRO-ID000',
    leader: '/assets/Image/photos/avatar-14.jpg',
    members: [
      '/assets/Image/photos/avatar-14.jpg',
      '/assets/Image/photos/avatar-18.jpg',
      '/assets/Image/photos/avatar-19.jpg',
    ],
    deadline: '12-02-2024',
    priority: 'Medium',
    budget: '4546469',
    status: 'Inactive',
    action: 'edit',
    viewdashboard: 'View Dashboard'
  },
  {
    id: 3,
    project: 'Hospital Administration',
    projectid: 'PRO-ID000',
    leader: '/assets/Image/photos/avatar-14.jpg',
    members: [
      '/assets/Image/photos/avatar-14.jpg',
      '/assets/Image/photos/avatar-18.jpg',
      '/assets/Image/photos/avatar-19.jpg',
    ],
    deadline: '12-02-2024',
    priority: 'Medium',
    budget: '4546469',
    status: 'Inactive',
    action: 'edit',
    viewdashboard: 'View Dashboard'
  },
  {
    id: 4,
    project: 'Hospital Administration',
    projectid: 'PRO-ID000',
    leader: '/assets/Image/photos/avatar-14.jpg',
    members: [
      '/assets/Image/photos/avatar-14.jpg',
      '/assets/Image/photos/avatar-18.jpg',
      '/assets/Image/photos/avatar-19.jpg',
    ],
    deadline: '12-02-2024',
    priority: 'Medium',
    budget: '4546469',
    status: 'Inactive',
    action: 'edit',
    viewdashboard: 'View Dashboard'
  },
  {
    id: 5,
    project: 'Hospital Administration',
    projectid: 'PRO-ID000',
    leader: '/assets/Image/photos/avatar-14.jpg',
    members: [
      '/assets/Image/photos/avatar-14.jpg',
      '/assets/Image/photos/avatar-18.jpg',
      '/assets/Image/photos/avatar-19.jpg',
    ],
    deadline: '12-02-2024',
    priority: 'Medium',
    budget: '4546469',
    status: 'Inactive',
    action: 'edit',
    viewdashboard: 'View Dashboard'
  },
];

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bolder",
      fontSize: "16px",
      borderBottom: "1px solid #000",
      textAlign: "center",
      borderRight: "0",
    },
  },
  rows: {
    style: {
      backgroundColor: '#ffffff',
      border: '1px solid #000',
      color: "#000",
      borderBottom: "0",
      borderTop: "0",
      borderRight: "0",
      borderLeft: "0",
    },
  },
  cells: {
    style: {
      borderBottom: "0",
      borderTop: "0",
      width: "100%",
      display: "grid",
      borderRight: "0",
      textAlign: 'center'
    },
  },
};

const conditionalRowStyles = [
  {
    when: row => row.id % 2 === 0,
    style: {
      backgroundColor: '#f2f2f2',
    },
  },
];

const CreateListView = ({ data, getDataAll }) => {
  // const [editModalShow, setEditModalShow] = useState(false);
  // const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [editCell, setEditCell] = useState(null);

  // const handleInputChange = (e, rowIndex, columnName) => {
  //   const newData = [...data];
  //   newData[rowIndex][columnName] = e.target.value;
  //   setData(newData);
  // };

  // const handleCellClick = (rowIndex, columnName) => {
  //   setEditCell({ rowIndex, columnName });
  // };

  // const handleBlur = () => {
  //   setEditCell(null);
  // };

  // const handleStatusChange = (index, newStatus) => {
  //   const newData = [...data];
  //   newData[index].status = newStatus;
  //   setData(newData);
  // };

  // const handlePriorityChange = (index, newPriority) => {
  //   const newData = [...data];
  //   newData[index].priority = newPriority;
  //   setData(newData);
  // };

  const getStatusColor = (status) => {
    return status === 'active' ? '#55CE63' : 'red';
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Low':
        return '#cfcb04';
      case 'Medium':
        return '#55CE63';
      case 'High':
        return 'red';
      default:
        return 'black';
    }
  };

  const { getData, postData, IMG_URL, deleteData, Select2Data, isAllow } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
  } = useForm();
  useEffect(() => {
    reset(data)
  }, [data]);

  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedClientId, setSelectedClientId] = useState(""); // Assuming initial status is active
  const [priorities, setPriorities] = useState([]);
  const [taskStatus, settaskStatus] = useState([]);

  const handleStatusChange = async (id, newStatus) => {
    const data = {
      status: newStatus,
    }
    await postData(`/projectmanagement/project/status/${id}`, data);
    getDataAll();
  };

  const handleDelete = async () => {
    const response = await deleteData(`/projectmanagement/project/${selectedClientId}`);
    setDeleteModalShow(false);
    getDataAll();
  };

  const GetAllPriorities = async () => {
    const res = await getData("/masters/all-priority-status");
    if (res?.success) {
      setPriorities(await Select2Data(res?.data, "priority_id"))
    }
  };

  const GetAllTAskStatus = async () => {
    const res = await getData("/masters/all-task-status");
    if (res?.success) {
      settaskStatus(await Select2Data(res?.data, "project_status_id"))
    }
  };

  const onSubmit = async (id, data, type) => {

    try {
      const sendData = new FormData();
      if (type === "priority") {
        sendData.append("priority_id", data);
      } else if (type === "project") {
        sendData.append("project_status_id", data);
      }

      const response = await postData(`/projectmanagement/project/${id}`, sendData);
      console.log(response);
      if (response?.success) {
        getDataAll();
      } else {

      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [editModalShow]);

  useEffect(() => {
    GetAllPriorities();
    GetAllTAskStatus();
  }, []);

  const columns = [
    {
      name: 'Project Name',
      selector: row => row?.name,
      sortable: true,
      center: true,
    },

    {
      name: 'Project Id',
      selector: row => row?.project_id,
      sortable: true,
      center: true,
    },
    {
      name: 'Project Leader',
      selector: row => <img src={IMG_URL + row?.team_leader?.image} alt={row?.team_leader?.name}
        width={40} height={40} style={{ borderRadius: '50%', margin: '6px' }} title={row?.team_leader?.name} />,
      sortable: true,
      center: true,
    },
    {
      name: 'Team Members',
      selector: row => (
        <div style={{ display: 'flex', }}>
          {row?.project_members?.map((data, index) => (
            <img
              key={index}
              src={IMG_URL + data?.user?.image}
              title={data?.user?.name}
              alt={`Member ${index + 1}`}
              width={30}
              height={30}
              style={{ borderRadius: '50%', margin: '6px -3px', flexShrink: 0 }}
            />
          ))}
          {/* <p className='count-number'>4<FontAwesomeIcon icon={faPlus} /></p> */}
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Deadline',
      selector: row => row?.e_date,
      sortable: true,
      center: true,

    },
    {
      name: 'Priority',
      center: true,
      selector: (row, index) => (
        <select
          disabled={!isAllow.includes(171)}
          value={row?.priority_id}
          onChange={(e) => handleSubmit(onSubmit(row?.id, e.target.value, "priority"))}
          style={{
            color: row?.priority_id == '1'
              ? '#55CE63'
              : row?.priority_id == '2'
                ? 'orange'
                : row?.priority_id == '3'
                  ? 'red'
                  : 'black',
            border: '1px solid #ccc',
            background: 'transparent',
            padding: '5px 8px',
            fontSize: '11px',
            fontWeight: '500',
            cursor: 'pointer',
            borderRadius: '50px',
          }}
        // option={priorities}
        >
          {priorities.map((priority) => (
            <option key={priority.value} value={priority.value}>
              {priority.label}
            </option>
          ))}

        </select>
      ),
      sortable: true
    },
    {
      name: 'Budget',
      selector: row => row?.budget,
      sortable: true,
      center: true,

    },
    {
      name: 'Project Status',
      center: true,
      selector: (row, index) => (
        <select
          disabled={!isAllow.includes(171)}
          value={row?.project_status_id}
          onChange={(e) => handleSubmit(onSubmit(row?.id, e.target.value, "project"))}
          style={{
            color: row?.project_status_id == '1'
              ? '#55CE63'
              : row?.project_status_id == '2'
                ? 'orange'
                : row?.project_status_id == '3'
                  ? 'red'
                  : 'black',
            border: '1px solid #ccc',
            background: 'transparent',
            padding: '5px 8px',
            fontSize: '11px',
            fontWeight: '500',
            cursor: 'pointer',
            borderRadius: '50px',
          }}
        // option={priorities}
        >
          {taskStatus.map((priority) => (
            <option key={priority.value} value={priority.value}>
              {priority.label}
            </option>
          ))}

        </select>
      ),
      sortable: true
    },
    {
      name: 'Status',
      center: true,
      selector: (row, index) => (
        <select
          disabled={!isAllow.includes(171)}
          value={row?.status}
          onChange={(e) => handleStatusChange(row?.id, e.target.value)}
          style={{
            color: row?.status == '1' ? '#55CE63' : 'red',
            border: '1px solid #ccc',
            background: 'transparent',
            padding: '5px 8px',
            fontSize: '11px',
            fontWeight: '500',
            cursor: 'pointer',
            borderRadius: '50px',
          }}
        >
          <option value="1">active</option>
          <option value="0">inactive</option>
        </select>
      ),
      sortable: true
    },
    isAllow.includes(171) ||
      isAllow.includes(172) ? (
      {
        name: 'Action',
        center: true,
        selector: row => (
          <div>
            {isAllow.includes(171) ? (
              <FontAwesomeIcon
                icon={faPenToSquare}
                onClick={() => {
                  setSelectedClientId(row?.id);
                  setEditModalShow(true);
                }}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              />
            ) : (
              <></>
            )}
            {isAllow.includes(172) ? (
              <FontAwesomeIcon
                icon={faTrashCan}
                onClick={() => {
                  setSelectedClientId(row?.id);
                  setDeleteModalShow(true);
                }}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <></>
            )}
            <ProjectEditModal
              show={editModalShow}
              onHide={() => setEditModalShow(false)}
              id={selectedClientId}
              getDataAll={getDataAll}
            />
            <ProjectDeleteModal
              show={deleteModalShow}
              onHide={() => setDeleteModalShow(false)}
              handleDelete={handleDelete}
              getDataAll={getDataAll}
            />
          </div>
        ),
        sortable: false
      }
    ) : (
      <></>
    ),
    {
      name: "View Dashboard",
      center: true,
      selector: row => (
        <div className='text-center'>
          <Link to={`/project-inner/${row?.id}`}><FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer', color: '#000' }} /></Link>
        </div >
      ),
      sortable: false
    },
  ];

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <section className='datatable' data-aos="fade-up" data-aos-duration="3000">
      <DataTable
        columns={columns}
        data={data?.data?.data}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </section>
  );
}

export default CreateListView;
