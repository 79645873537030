import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Breadcrumb, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import ProjectClientModal from './ProjectClientModal/ProjectClientAddModal';
// import ProjectClientEditModal from './ProjectClientModal/ProjectClientEditModal';
// import ProjectDeleteClientModal from './ProjectClientModal/ProjectDeleteClientModal';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ProjectTeamModal from './ProjectTeamModal/ProjectTeamModal';
import ProjectTeamListView from './ProjectTeamListView';
import { Context } from "../../../utils/context";


const options = [
    { value: 'Team Leader', label: 'Team Leader' },
    { value: 'Team Name', label: 'Team Name' },
];

const ProjectTeam = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [modalShow, setModalShow] = useState(false);

    const [tls, setTls] = useState([]);
    const [projects, setProjects] = useState([]);

    const [search, setSearchName] = useState("");
    const [team_leader, setTeamLeader] = useState();
    const [project, setProject] = useState();

    const { getData, isAllow, Select2Data } = useContext(Context);
    const [data, setData] = useState();

    const getDataAll = async () => {
        const response = await getData(
            `/projectmanagement/project?term=${search}&team_leader=${team_leader?.value || ""}&project=${project?.value || ""}`
        );
        await setData(response);
    };

    // const getDataAll = async () => {
    //     const response = await getData(
    //         `/projectmanagement/team?term=${search}&project=${project?.value || ""}&team_leader=${team_leader?.value || ""}`
    //     );
    //     await setData(response);

    // };
    useEffect(() => {
        getDataAll();
    }, [search, team_leader, project, modalShow]);

    const GetAllTeamLeaders = async () => {
        const res = await getData("/masters/allteamleaders");
        if (res?.success) {
            setTls(await Select2Data(res?.data, "team_leader_id"));
        }
    };
    const GetAllProjects = async () => {
        const res = await getData("/project_management/allproject");
        if (res?.success) {
            setProjects(await Select2Data(res?.data, "project_id"))
        }
    };
    useEffect(() => {
        GetAllTeamLeaders();
        GetAllProjects();
        getDataAll();
    }, []);

    return (
        <section className='project-add'>
            <div className='d-flex justify-content-between'>
                <div className='main-title'>
                    <h2>Team</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Team</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {/* {isAllow.includes(190) ? (
                    <div>
                        <button className='create-project-btn mt-2' onClick={() => setModalShow(true)} ><FontAwesomeIcon icon={faPlus} className='me-1 mt-1' /> Create Team</button>
                        <ProjectTeamModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </div>
                ) : (
                    <></>
                )} */}
            </div>
            <Row>
                <Col xl={2} lg={3} md={6} sm={12} xs={12} >
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                            <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="Project Name"
                                value={search}
                                onChange={(e) => {
                                    setSearchName(e.target.value);
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xl={2} lg={3} md={6} sm={12} xs={12} >
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            {/* <Form.Label>Employee Name</Form.Label> */}
                            <Select
                                isSearchable
                                options={tls}
                                value={team_leader}
                                placeholder="Team Leader"
                                onChange={(e) => {
                                    setTeamLeader(e);
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    <Select
                        isSearchable
                        options={projects}
                        value={project}
                        placeholder="Project"
                        onChange={(e) => {
                            setProject(e);
                        }}
                    />
                </Col>
                {/* <Col lg={2} md={4} sm={8} xs={8}>
                    <button
                        className='search-button mt-3  mt-md-0'
                        onClick={getDataAll}
                    >Search</button>


                </Col> */}
                <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    <button
                        className='search-button mt-3 mt-md-0'
                        onClick={async () => {
                            setSearchName("");
                            setProject("");
                            setTeamLeader("");
                            // await getDataAll();
                        }}
                    >
                        Clear
                    </button></Col>
            </Row>
            {/* Listview */}
            <ProjectTeamListView
                data={data}
                getDataAll={getDataAll}
            />
        </section>
    )
}

export default ProjectTeam;
