import React from 'react'
import CanvasJSReact from '@canvasjs/react-charts';




const StatusViewChart = ({ pieData }) => {
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

    console.log("pieData", pieData);


    const dataPoints = pieData?.data?.map(item => ({
        name: item.status_name,
        y: item.task_count
    }));

    const options = {
        animationEnabled: true,
        // title: {
        //     text: "Status Overview"
        // },
        subtitles: [{
            text: pieData?.completed + "%",
            verticalAlign: "center",
            fontSize: 24,
            dockInsidePlotArea: true
        }],
        data: [{
            type: "doughnut",
            showInLegend: true,
            indexLabel: "{name}: {y}",
            yValueFormatString: "#,###'%'",
            dataPoints: dataPoints
        }]
    }
    return (
        <div>
            <CanvasJSChart options={options} />
        </div>
    )
}

export default StatusViewChart


