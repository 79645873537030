import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';

import {
  getData,
  postData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from './api';

import {
  Per_Page_Dropdown,
  RequiredIs,
  Select2Data,
  getDimension,
  Select3Data,
} from './common';

export const Context = createContext();

const AppContext = ({ children }) => {
  const [complaintstatus, setComplaintStatus] = useState({});
  // const IMG_URL = "http://127.0.0.1:1621";
  // const IMG_URL = "http://192.168.14.114:4000/";
  // const IMG_URL = "https://itpulsenode.profcyma.org:1620";
  // const IMG_URL = "https://itpulsenode.profcymabackend.com:1620";
  const IMG_URL = "https://itpulsenodenew.profcymabackend.com:1621";
  const navigate = useNavigate();
  const [signin, setSignin] = useState(false);
  const [usertype, setUsertype] = useState('');
  const [userdata, setUserData] = useState({});
  const [isAllow, setisAllow] = useState([]);

  const minLength = 2;
  const maxLength = 30;

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const getuserData = async (id) => {
    const response = await getData(`/login/${id}`, { id: id });
    setUserData(response?.data?.data);
    await setisAllow(response?.data.permissions);
  };

  useEffect(() => {
    console.log(signin, "kkk");

    isTokenValid();
  }, [signin]);

  const isTokenValid = async () => {
    const token = Cookies.get('projectmanagement_security');
    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        Cookies.remove('projectmanagement_security', { path: '/' });
        setSignin(false);
        setUsertype('');
        setisAllow([]);
        navigate('/login');
      } else {
        setisAllow(decodedToken?.permissions);
        getuserData(decodedToken.user);
        setUsertype(decodedToken.rolename);
        setSignin(true);
        // navigate('/');
      }
    } else {
      setisAllow([]);
      setSignin(false);
      setUsertype('');
      navigate('/login');
    }
  };

  const ErrorNotify = (name) => {
    toast.error(`${name} deleted successfully.`, {
      position: 'top-right',
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
      style: { background: 'red', color: 'white' },
    });
  };

  return (
    <Context.Provider
      value={{
        getData,
        postData,
        editStatusData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        getDownloadDataExcel,
        ErrorNotify,
        setisAllow,
        isAllow,
        Per_Page_Dropdown,
        IMG_URL,
        RequiredIs,
        Select2Data,
        Select3Data,
        getDimension,
        complaintstatus,
        setComplaintStatus,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
