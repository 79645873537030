import React, { useContext, useState, useEffect } from "react";
import { Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import ProjectDeleteModal from './ProjectAddModal/ProjectDeleteModal';
import ProjectEditModal from './ProjectAddModal/ProjectEditModal';
import { Link } from 'react-router-dom';
import AddPeopleModal from './ProjectInnerPage/AddPeopleModal/AddPeopleModal';
import { Context } from "../../../utils/context";
import './ProjectAdd.css';

const CreateGridView = ({ data, getDataAll }) => {
    const { getData, deleteData, isAllow, IMG_URL } = useContext(Context);

    const [dropdownVisibleIndex, setDropdownVisibleIndex] = useState(null);
    const [editModalShow, setEditModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [peopleModalShow, setPeopleModalShow] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState("");
    const [progressData, setProgressData] = useState({});

    const toggleDropdown = (index) => {
        setDropdownVisibleIndex(dropdownVisibleIndex === index ? null : index);
    };

    const handleDelete = async () => {
        const response = await deleteData(`/projectmanagement/project/${selectedClientId}`);
        setDeleteModalShow(false);
        getDataAll();
    };


    const getProgress = async (id) => {
        const result = await getData(`/projectmanagement/project/progress/${id}`);
        return parseFloat(result?.data?.progress || 0);
    };

    useEffect(() => {
        data?.data?.data?.forEach(async (project) => {
            const progress = await getProgress(project.id);
            setProgressData(prev => ({ ...prev, [project.id]: progress }));
        });
    }, [data]);

    console.log("progressData", progressData);
    return (
        <section className='create-project'>
            <Row>
                {data?.data?.data?.map((project, index) => (
                    <Col xxl={3} xl={4} lg={6} md={12} sm={12} xs={12} key={index} className='mt-3' >
                        <div className='white-bgggg'>
                            <div className="text-end">
                                {
                                    isAllow.includes(171) ? (
                                        <div onClick={() => toggleDropdown(index)}>
                                            <FontAwesomeIcon icon={faEllipsisVertical} className='ellips-img' />
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                                {dropdownVisibleIndex === index && (
                                    <div className="drop-down-bgg">
                                        {isAllow.includes(171) && (
                                            <p className="client-designation" onClick={() => setEditModalShow(true)}>
                                                <FontAwesomeIcon icon={faPenToSquare} /> Edit
                                            </p>
                                        )}

                                        <ProjectEditModal
                                            show={editModalShow}
                                            onHide={() => setEditModalShow(false)}
                                            id={project?.id}
                                        />
                                        {isAllow.includes(172) && (
                                            <p className="client-designation"
                                                onClick={() => {
                                                    setDeleteModalShow(true);
                                                    setSelectedClientId(project?.id);
                                                }}>
                                                <FontAwesomeIcon icon={faTrashCan} /> Delete
                                            </p>
                                        )}
                                        <ProjectDeleteModal
                                            show={deleteModalShow}
                                            onHide={() => setDeleteModalShow(false)}
                                            handleDelete={handleDelete}
                                        />
                                    </div>
                                )}
                            </div>
                            <Link to={`/project-inner/${project?.id}`}>
                                <h4 className='project-name'>{project.name}</h4>
                                <span className='project-txtt mb-2'>{project.openTasks} open tasks, {project.completedTasks} tasks completed</span>
                                <span className='black-project-txtt'>Deadline : </span>
                                <span className='project-txtt'>{project?.e_date}</span>
                            </Link>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='black-project-txtt mt-3'>Project Leader :</p>
                                    {/* <span className='project-txtt'>{project?.team_leader?.name}</span> */}
                                    <img
                                        key={index}
                                        src={IMG_URL + project?.team_leader?.image}
                                        title={project?.team_leader?.name}
                                        alt={`Member ${index + 1}`}
                                        width={30}
                                        height={30}
                                        style={{ borderRadius: '50%', margin: '6px -3px', flexShrink: 0 }}
                                    />
                                </div>
                                <div>
                                    <p className='black-project-txtt mt-3'>Team :</p>
                                    <div className='d-flex'>
                                        {/* {project?.project_members?.map((data) => data?.user?.name).join(', ')} */}
                                        {project?.project_members?.map((data, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '6px' }}>
                                                <img
                                                    src={IMG_URL + data?.user?.image}
                                                    title={data?.user?.name}
                                                    alt={`Member ${index + 1}`}
                                                    width={30}
                                                    height={30}
                                                    style={{ borderRadius: '50%', marginRight: '8px', flexShrink: 0 }}
                                                />
                                                {/* <span>{data?.user?.name}</span> */}
                                            </div>
                                        ))}

                                        <AddPeopleModal
                                            show={peopleModalShow}
                                            onHide={() => setPeopleModalShow(false)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <p className='black-project-txtt mt-4'>Progress : </p>
                            <ProgressBar now={progressData[project?.id] || 0} label={`${progressData[project?.id] || 0}%`} />
                        </div>
                    </Col>
                ))}
            </Row>
        </section>
    );
};

export default CreateGridView;
