import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Breadcrumb, Col, Form, Row } from 'react-bootstrap'
import ProjectClientModal from '../ProjectClient/ProjectClientModal/ProjectClientAddModal'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select';
import AddTaskModal from './ProjectTaskModal/AddTaskModal'
import ProjectTaskTable from './ProjectTaskTable'

import { Context } from "../../../utils/context";

const ProjectTask = () => {
    const [modalShow, setModalShow] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const { getData, postData, isAllow, deleteData, Select2Data, Per_Page_Dropdown, } = useContext(Context);
    const [data, setData] = useState();

    const [projects, setProjects] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [taskStatuses, setTaskStatuses] = useState([]);
    const [users, setUsers] = useState([]);

    const [search, setSearchName] = useState("");
    const [project, setProject] = useState();
    const [priority, setPriority] = useState();
    const [assign_to, setAssignTo] = useState();
    const [assign_by, setAssignBy] = useState();
    const [task_status, setTaskStatus] = useState();

    const getDataAll = async () => {
        const response = await getData(
            `/projectmanagement/task?term=${search}&project=${project?.value || ""}&priority=${priority?.value || ""}` +
            `&assign_to=${assign_to?.value || ""}&assign_by=${assign_by?.value || ""}&task_status=${task_status?.value || ""}`
        );
        await setData(response);

    };
    useEffect(() => {
        getDataAll();
    }, [search, priority, project, assign_to, assign_by, task_status]);

    const GetAllProjects = async () => {
        const res = await getData("/project_management/allproject");
        if (res?.success) {
            setProjects(await Select2Data(res?.data, "project_id"))
        }
    };

    const GetAllPriorities = async () => {
        const res = await getData("/masters/all-priority-status");
        if (res?.success) {
            setPriorities(await Select2Data(res?.data, "priority_id"))
        }
    };

    const GetAllEmployees = async () => {
        const res = await getData("/masters/allteamleaders");
        if (res?.success) {
            setUsers(await Select2Data(res?.data, "user_id"));
        }
    };

    const GetAllTAskStatus = async () => {
        const res = await getData("/masters/all-task-status");
        if (res?.success) {
            setTaskStatuses(await Select2Data(res?.data, "task_status_id"))
        }
    };
    useEffect(() => {
        GetAllEmployees();
        GetAllProjects();
        GetAllPriorities();
        GetAllTAskStatus();
        getDataAll();
    }, []);
    return (
        <section className='project-add'>
            <div className='d-flex justify-content-between'>
                <div className='main-title'>
                    <h2>Task</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/project-dashboard">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Task</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {isAllow.includes(174) ? (
                    <div>
                        <button className='create-project-btn mt-2' onClick={() => setModalShow(true)} ><FontAwesomeIcon icon={faPlus} className='me-1 mt-1' /> Add Task</button>
                        <AddTaskModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            getDataAll={getDataAll}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <Row>
                <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="Task Name"
                                value={search}
                                onChange={(e) => {
                                    setSearchName(e.target.value);
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Select
                                isSearchable
                                options={projects}
                                value={project}
                                placeholder="Project"
                                onChange={(e) => {
                                    setProject(e);
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    <Select
                        isSearchable
                        options={priorities}
                        value={priority}
                        placeholder="Priority"
                        onChange={(e) => {
                            setPriority(e);
                        }}
                    />
                </Col>
                <Col xl={2} lg={3} md={6} sm={12} xs={12} className='mt-3 mt-md-0 mt-lg-0'>
                    <Select
                        isSearchable
                        options={taskStatuses}
                        value={task_status}
                        placeholder="Task Status"
                        onChange={(e) => {
                            setTaskStatus(e);
                        }}
                    />
                </Col>
                {/* <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    <Select
                        isSearchable
                        options={users}
                        value={assign_by}
                        placeholder="Assign By"
                        onChange={(e) => {
                            setAssignBy(e);
                        }}
                    />
                </Col> */}
                <Col xl={2} lg={3} md={6} sm={12} xs={12} className='mt-3 mt-md-3 mt-lg-0'>
                    <button
                        className='search-button mt-3 mt-md-0'
                        onClick={async () => {
                            setSearchName("");
                            setProject("");
                            setPriority("");
                            setTaskStatus("");
                            setAssignBy("");
                            // await getDataAll();
                        }}
                    >
                        Clear
                    </button>
                </Col>
            </Row>
            <ProjectTaskTable
                data={data}
                getDataAll={getDataAll}
            />
        </section >
    )
}

export default ProjectTask