import React, { useContext } from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './TaskDetailModal.css'
import parse from "html-react-parser";
import { Context } from "../../../../../utils/context";

const TaskDetailModal = (props) => {

    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const id = props?.id;
    const { getData, postData, Select2Data } = useContext(Context);
    const [data, setData] = useState({})

    const GetEditData = async () => {
        const res = await getData(`/projectmanagement/task/${id}`);
        if (res?.success) {
            setData(res?.data)
        }
    };

    useEffect(() => {
        GetEditData()
    }, [id]);

    console.log("idididididi:---", id);


    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter "
            centered
            scrollable
            className='project-client-modal'
        >
            <Modal.Header closeButton>
                {/* <Modal.Title id="contained-modal-title-vcenter">
                  Modal heading
              </Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col lg={8}>
                            <h5>1. {data?.name}</h5>
                            <p className='titlte-modal'>Description</p>
                            <p className='user-txtttt'>{htmlToReactParser.parse(data?.description) || ""}</p>
                            <p className='titlte-modal'>Activity</p>
                            <div>
                                <div className='d-flex'>
                                    <p className='usernameeee me-2'>AK</p>
                                    <p className='me-2 user-txtttt'>Amarendra kumar created the Issue</p>
                                    <p className='date-classs' >5 July 2024 at 18:20</p>
                                </div>
                            </div>
                            <div>
                                <div className='d-flex'>
                                    <p className='usernameeee me-2'>AK</p>
                                    <p className='me-2 user-txtttt'>Amarendra kumar created the Issue</p>
                                    <p className='date-classs' >5 July 2024 at 18:20</p>
                                </div>
                            </div>
                            <div>
                                <div className='d-flex'>
                                    <p className='usernameeee me-2'>AK</p>
                                    <p className='me-2 user-txtttt'>Amarendra kumar created the Issue</p>
                                    <p className='date-classs' >5 July 2024 at 18:20</p>
                                </div>
                            </div>
                            <div>
                                <div className='d-flex'>
                                    <p className='usernameeee me-2'>AK</p>
                                    <p className='me-2 user-txtttt'>Amarendra kumar created the Issue</p>
                                    <p className='date-classs' >5 July 2024 at 18:20</p>
                                </div>
                            </div>
                            <div>
                                <div className='d-flex'>
                                    <p className='usernameeee me-2'>AK</p>
                                    <p className='me-2 user-txtttt'>Amarendra kumar created the Issue</p>
                                    <p className='date-classs' >5 July 2024 at 18:20</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Details</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={4}>
                                                <p className='user-txtttt-bold'>Assignee</p>
                                            </Col>
                                            <Col lg={8}>
                                                <div className='d-flex'>
                                                    {/* <p className='usernameeee me-2'>AK</p> */}
                                                    <p className='me-2 user-txtttt'>{data?.assign_by_id?.label}</p>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <p className='user-txtttt-bold'>Priority</p>
                                            </Col>
                                            <Col lg={8}>
                                                <p className='user-txtttt'>{data?.priority_id?.label}</p>
                                            </Col>
                                            <Col lg={4}>
                                                <p className='user-txtttt-bold'>Due date</p>
                                            </Col>
                                            <Col lg={8}>
                                                <p className='user-txtttt'>{data?.due_date}</p>
                                            </Col>
                                            <Col lg={4}>
                                                <p className='user-txtttt-bold'>Actual end</p>
                                            </Col>
                                            <Col lg={8}>
                                                <p className='user-txtttt'>{data?.e_date}</p>
                                            </Col>
                                            <Col lg={4}>
                                                <p className='user-txtttt-bold'>Actual start</p>
                                            </Col>
                                            <Col lg={8}>
                                                <p className='user-txtttt'>{data?.s_date}</p>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    )
}

export default TaskDetailModal