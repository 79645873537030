import React, { useContext, useState, useEffect } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Context } from "../../../../../utils/context";

const CalendarTab = ({ project_id, tasks }) => {
  const { getData } = useContext(Context);
  const [taskData, setTaskData] = useState({});
  const [data, setData] = useState({});
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    setData(tasks?.data?.data)
  }, [tasks]);

  useEffect(() => {
    if (data?.length > 0) {
      const processedData = data.reduce((acc, task) => {
        const date = new Date(task.s_date).toDateString();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(task.name);
        return acc;
      }, {});
      setTaskData(processedData);
    }
  }, [data, tasks]);

  return (
    <div className='calendar-tab'>
      <Calendar
        onChange={setValue}
        value={value}
        tileContent={({ date, view }) => {
          if (view === 'month') {
            const dateString = date.toDateString();
            if (taskData && taskData[dateString]) {
              return (
                <ul>
                  {taskData[dateString].map((task, index) => (
                    <li key={index} className="task-name" style={{ color: 'orange' }}>{task}</li>
                  ))}
                </ul>
              );
            }
          }
          return null;
        }}
      />
    </div>
  );
}

export default CalendarTab;
