import React, { useContext } from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

import { Row, Col, Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../utils/context";

const options = [
  { value: 'Frontend', label: 'Frontend' },
  { value: 'Backend', label: 'Backend' },
  { value: 'Tester', label: 'Tester' },
];

const EditUserModal = (props) => {

  const id = props?.id;
  const [selectedOption, setSelectedOption] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
  } = useForm();

  const { getData, postData, Select2Data } = useContext(Context);

  const [role, setRole] = useState([]);
  const [departments, setDepartments] = useState([]);

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const dataToSend = new FormData();
      dataToSend.append("role_id", data?.role_id.value);
      dataToSend.append("department_id", data?.department_id.value);
      dataToSend.append("name", data?.name);
      dataToSend.append("mobile_number", data?.mobile_number);
      dataToSend.append("email", data?.email);
      dataToSend.append("password", data?.password);
      dataToSend.append("cpassword", data?.cpassword);
      if (data?.image) {
        dataToSend.append("image", data?.image[0]);
      }

      const response = await postData(`/employee/employee_details/${id}`, dataToSend);
      console.log(response);
      if (response?.success) {
        props.onHide();
        props.getDataAll();
        reset();
      } else {

      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllRole = async () => {
    const res = await getData("/masters/allroles");
    if (res?.success) {
      setRole(await Select2Data(res?.data, "role_id"))
    }
  }

  const GetAllDepartments = async () => {
    const res = await getData("/masters/alldepartments");
    if (res?.success) {
      setDepartments(await Select2Data(res?.data, "department_id"))
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/employee/employee_details/${id}`);
    reset(response?.data)
  };

  useEffect(() => {
    GetEditData();
  }, [id]);

  useEffect(() => {
    GetAllRole();
    GetAllDepartments();
  }, []);


  return (
    <section>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='project-client-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formUserName">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.name,
                    })}
                    type="text"
                    {...register("name", {
                      required: "Name is required",
                    })}
                    placeholder="Name"
                  />
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formUserRole">
                  <Form.Label>User Role</Form.Label>
                  <Controller
                    name="role_id" // name of the field
                    {...register("role_id", {
                      required: "Role  is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.role_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={role}
                      />
                    )}
                  />

                  {errors.role_id && (
                    <span className="text-danger">
                      {errors.role_id.message}
                    </span>
                  )}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formUserRole">
                  <Form.Label>Department</Form.Label>
                  <Controller
                    name="department_id" // name of the field
                    {...register("department_id", {
                      required: "Department  is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.department_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={departments}
                      />
                    )}
                  />

                  {errors.department_id && (
                    <span className="text-danger">
                      {errors.department_id.message}
                    </span>
                  )}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formMobile">
                  <Form.Label>Mobile</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="mobile_number"
                      placeholder="Contact No."
                      {...register("mobile_number", {
                        required: "Contact number is required",
                        validate: {
                          isTenDigits: (value) =>
                            value && value.length === 10 || "Contact number must be 10 digits",
                          isNumeric: (value) =>
                            /^\d+$/.test(value) || "Contact number must be numeric"
                        }
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.mobile_number,
                        "is-valid": !errors?.mobile_number && getValues("mobile_number")?.length === 10,
                      })}
                      onKeyDown={(event) => {
                        const { key } = event;
                        if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab") {
                          event.preventDefault();
                        }
                        if (event.target.value?.length >= 10 && key !== "Backspace" && key !== "Tab") {
                          event.preventDefault();
                        }
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="User Email"
                        className={classNames("", {
                          "is-invalid": errors?.email,
                          "is-valid": !errors?.email && getValues("email"),
                        })}
                        {...register("email", {
                          required: "User Email is required",
                          pattern: {
                            value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                            message: "Invalid E-Mail Address",
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                  </Form.Group>
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3" controlId="formConfirmPassword">
                  <Form.Label>User Image</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.image,
                      "is-valid": !errors?.image && getValues("image"),
                    })}
                    type="file"

                    {...register("image", {
                      // required: "images is required",
                    })}

                    accept="image/*"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Modal.Footer>
              <div className='mx-auto'>
                <button
                  // onClick={props.onHide}
                  type="submit"
                  className='submit-btn me-2'
                >
                  <FontAwesomeIcon icon={faFloppyDisk} /> Save
                </button>
                <button onClick={props.onHide} className='submit-btn'>
                  <FontAwesomeIcon icon={faXmark} /> Cancel
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal.Body>

      </Modal>
    </section>
  );
}

export default EditUserModal;
