import { faBoxesStacked, faGear, faHouse, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from "react";
import { Accordion, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Context } from "../../../utils/context";
const ProjectSideBarMobile = () => {
    const { isAllow } = useContext(Context);
    return (
        <div className="project-sidebar">
            <Row>
                <Col xl={12}>
                    <Accordion defaultActiveKey={0} >
                        <Link to='/'>
                            <Accordion.Item eventKey="0" className='acoor-dummy'>
                                <Accordion.Header>
                                    <FontAwesomeIcon icon={faHouse} className='me-3' />
                                    <span >Dashboard</span>
                                </Accordion.Header>
                            </Accordion.Item>
                        </Link>

                        {/* {isAllow.includes(1) &&
                            isAllow.includes(5) ? (
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <FontAwesomeIcon icon={faUser} className='me-3' />
                                    <span >Users</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        {isAllow.includes(5) ? (
                                            <Link to='/add-user'><li><a>Add User</a></li></Link>
                                        ) : (
                                            <></>
                                        )}
                                        {isAllow.includes(1) ? (
                                            <Link to='/add-role'> <li><a>Set Permission as per role</a></li></Link>
                                        ) : (
                                            <></>
                                        )}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        ) : (
                            <></>
                        )} */}
                        {isAllow.includes(177) ? (
                            <Link to='/project-client'>
                                <Accordion.Item eventKey="2" className='acoor-dummy'>
                                    <Accordion.Header>
                                        <FontAwesomeIcon icon={faUsers} className='me-3' />
                                        <span >Clients</span>
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                        ) : (
                            <></>
                        )}

                        {isAllow.includes(169) ? (
                            <Link to='/project-add'>
                                <Accordion.Item eventKey="3" className='acoor-dummy'>
                                    <Accordion.Header>
                                        <FontAwesomeIcon icon={faBoxesStacked} className='me-3' />
                                        <span >Projects</span>
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                        ) : (
                            <></>
                        )}

                        {isAllow.includes(189) ? (
                            <Link to='/project-team'>
                                <Accordion.Item eventKey="4" className='acoor-dummy'>
                                    <Accordion.Header>
                                        <FontAwesomeIcon icon={faUsers} className='me-3' />
                                        <span >Team</span>
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                        ) : (
                            <></>
                        )}

                        {isAllow.includes(173) ? (
                            <Link to='/project-task'>
                                <Accordion.Item eventKey="5" className='acoor-dummy'>
                                    <Accordion.Header>
                                        <FontAwesomeIcon icon={faGear} className='me-3' />
                                        <span >Tasks</span>
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Link>
                        ) : (
                            <></>
                        )}
                    </Accordion>
                </Col>
            </Row>
        </div>
    )
}

export default ProjectSideBarMobile