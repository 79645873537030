import React, { useContext } from "react";
import { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import ProjectDeleteModal from '../ProjectAdd/ProjectAddModal/ProjectDeleteModal';
import EditTaskModal from './ProjectTaskModal/EditTaskModal';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Context } from "../../../utils/context";
import { useForm, Controller } from "react-hook-form";
import { Await } from "react-router-dom";

// teamData
export const teamData = [
    {
        id: 1,
        key: 'K012345',
        projectname: 'Boomer Bucks',
        tasktitle: 'Registration Form',
        description: 'PRO-ID000 description',
        assigned: 'Disha Marathe',
        duedate: '12-02-2024',
        startdate: '12-02-2024',
        enddate: '12-02-2024',
        priority: 'High',
        status: 'Done',
        taskstatus: 'Active',
        action: 'edit',
    },
    {
        id: 2,
        key: 'K012345',
        projectname: 'Boomer Bucks',
        tasktitle: 'Registration Form',
        description: 'PRO-ID000 description',
        assigned: 'Disha Marathe',
        duedate: '12-02-2024',
        startdate: '12-02-2024',
        enddate: '12-02-2024',
        priority: 'High',
        status: 'In Process',
        taskstatus: 'Active',
        action: 'edit',
    },
    {
        id: 3,
        key: 'K012345',
        projectname: 'Boomer Bucks',
        tasktitle: 'Registration Form',
        description: 'PRO-ID000 description',
        assigned: 'Disha Marathe',
        duedate: '12-02-2024',
        startdate: '12-02-2024',
        enddate: '12-02-2024',
        priority: 'Low',
        status: 'Done',
        taskstatus: 'Inactive',
        action: 'edit',
    },
    {
        id: 4,
        key: 'K012345',
        projectname: 'Boomer Bucks',
        tasktitle: 'Registration Form',
        description: 'PRO-ID000 description',
        assigned: 'Disha Marathe',
        duedate: '12-02-2024',
        startdate: '12-02-2024',
        enddate: '12-02-2024',
        priority: 'High',
        status: 'To Do',
        taskstatus: 'Inactive',
        action: 'edit',
    },
    {
        id: 5,
        key: 'K012345',
        projectname: 'Boomer Bucks',
        tasktitle: 'Registration Form',
        description: 'PRO-ID000 description',
        assigned: 'Disha Marathe',
        duedate: '12-02-2024',
        startdate: '12-02-2024',
        enddate: '12-02-2024',
        priority: 'Medium',
        status: 'In Process',
        taskstatus: 'Inactive',
        action: 'edit',
    },
    {
        id: 6,
        key: 'K012345',
        projectname: 'Boomer Bucks',
        tasktitle: 'Registration Form',
        description: 'PRO-ID000 description',
        assigned: 'Disha Marathe',
        duedate: '12-02-2024',
        startdate: '12-02-2024',
        enddate: '12-02-2024',
        priority: 'Medium',
        status: 'In Process',
        taskstatus: 'Inactive',
        action: 'edit',
    },
    {
        id: 7,
        key: 'K012345',
        projectname: 'Boomer Bucks',
        tasktitle: 'Registration Form',
        description: 'PRO-ID000 description',
        assigned: 'Disha Marathe',
        duedate: '12-02-2024',
        startdate: '12-02-2024',
        enddate: '12-02-2024',
        priority: 'Medium',
        status: 'In Process',
        taskstatus: 'Inactive',
        action: 'edit',
    },
    {
        id: 8,
        key: 'K012345',
        projectname: 'Boomer Bucks',
        tasktitle: 'Registration Form',
        description: 'PRO-ID000 description',
        assigned: 'Disha Marathe',
        duedate: '12-02-2024',
        startdate: '12-02-2024',
        enddate: '12-02-2024',
        priority: 'Medium',
        status: 'In Process',
        taskstatus: 'Inactive',
        action: 'edit',
    },
    {
        id: 9,
        key: 'K012345',
        projectname: 'Boomer Bucks',
        tasktitle: 'Registration Form',
        description: 'PRO-ID000 description',
        assigned: 'Disha Marathe',
        duedate: '12-02-2024',
        startdate: '12-02-2024',
        enddate: '12-02-2024',
        priority: 'Medium',
        status: 'In Process',
        taskstatus: 'Inactive',
        action: 'edit',
    },
];

const customStyles = {
    headCells: {
        style: {
            fontWeight: "bolder",
            fontSize: "16px",
            borderBottom: "1px solid #000",
            textAlign: "center",
            borderRight: "0",
        },
    },
    rows: {
        style: {
            backgroundColor: '#ffffff',
            border: '1px solid #000',
            color: "#000",
            borderBottom: "0",
            borderTop: "0",
            borderRight: "0",
            borderLeft: "0",
        },
    },
    cells: {
        style: {
            borderBottom: "0",
            borderTop: "0",
            width: "100%",
            display: "grid",
            borderRight: "0",
        },
    },
};

const getStatusColor = (status) => {
    switch (status) {
        case 'To Do':
            return 'black';
        case 'In Process':
            return 'blue';
        case 'Done':
            return 'green';
        default:
            return 'black';
    }
};

const getPriorityColor = (priority) => {
    switch (priority) {
        case 'Low':
            return '#c4bb0c';
        case 'Medium':
            return '#55CE63';
        case 'High':
            return 'red';
        default:
            return 'black';
    }
};



const ProjectTaskTable = ({ data, getDataAll }) => {
    const [editModalShow, setEditModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [editingCell, setEditingCell] = useState(null);
    const [tasks, setTasks] = useState(teamData);

    const [priorities, setPriorities] = useState([]);
    const [taskStatus, settaskStatus] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState("");

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        watch,
        getValues,
        reset,
    } = useForm();

    const { getData, postData, isAllow, deleteData, Select2Data, IMG_URL } = useContext(Context);

    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();

    useEffect(() => {
        AOS.init({ once: true });
    }, []);

    const handleCellClick = (rowIndex, columnName) => {
        setEditingCell({ rowIndex, columnName });
    };

    const handleInputChange = (e, rowIndex, columnName) => {
        const newData = [...tasks];
        newData[rowIndex][columnName] = e.target.value;
        setTasks(newData);
    };

    const handleInputBlur = () => {
        setEditingCell(null);
    };

    const handlePriorityChange = (index, newPriority) => {
        const updatedTasks = [...tasks];
        updatedTasks[index].priority = newPriority;
        setTasks(updatedTasks);
    };


    const handleTaskStatusChange = (index, newTaskStatus) => {
        const updatedTasks = [...tasks];
        updatedTasks[index].taskstatus = newTaskStatus;
        setTasks(updatedTasks);
    };

    const handleStatusChange = async (id, newStatus) => {
        const data = {
            status: newStatus,
        }
        await postData(`/projectmanagement/task/status/${id}`, data);
        getDataAll();
    };

    const handleDelete = async () => {
        const response = await deleteData(`/projectmanagement/task/${selectedClientId}`);
        setDeleteModalShow(false);
        getDataAll();
    };

    const GetAllPriorities = async () => {
        const res = await getData("/masters/all-priority-status");
        if (res?.success) {
            setPriorities(await Select2Data(res?.data, "priority_id"))
        }
    };

    const GetAllTAskStatus = async () => {
        const res = await getData("/masters/all-task-status");
        if (res?.success) {
            settaskStatus(await Select2Data(res?.data, "task_status_id"))
        }
    };

    useEffect(() => {
        GetAllPriorities();
        GetAllTAskStatus();
    }, []);

    const onSubmit = async (id, data, type) => {

        try {
            const sendData = new FormData();
            if (type === "priority_id") {
                sendData.append("priority_id", data);
            } else if (type === "task_status_id") {
                sendData.append("task_status_id", data);
            }
            const response = await postData(`/projectmanagement/task/${id}`, sendData);
            console.log(response);
            if (response?.success) {
                console.log("working here");

                await getDataAll();
            } else {

            }
        } catch (error) {
            console.log(error);
        }
    };


    const columns = [
        {
            name: 'Key',
            selector: row => row?.task_id,
            sortable: true,
            center: true,
        },
        {
            name: 'Project Name',
            selector: row => row?.project?.name,
            sortable: true,
            center: true,
        },
        {
            name: 'Task Title',
            selector: row => row?.name,
            sortable: true,
            center: true,
        },
        {
            name: 'Description',
            selector: row => htmlToReactParser.parse(row?.description),
            sortable: true,
            center: true,
        },
        {
            name: 'Assigned To',
            selector: row => <img src={IMG_URL + row?.task_assign_too?.image}
                alt={row.task_assign_too?.name} width={40} height={40} style={{ borderRadius: '50%', margin: '6px' }}
                title={row.task_assign_too?.name}
            />,
            // row?.task_assign_by?.name,
            sortable: true,
            center: true,
        },
        {
            name: 'Assigned By',
            selector: row => <img src={IMG_URL + row?.task_assign_by?.image}
                alt={row.task_assign_by?.name} width={40} height={40} style={{ borderRadius: '50%', margin: '6px' }}
                title={row.task_assign_by?.name}
            />,
            // row?.task_assign_by?.name,
            sortable: true,
            center: true,
        },
        {
            name: 'Priority',
            center: true,
            selector: (row, index) => (
                <select
                    disabled={!isAllow.includes(175)}
                    value={row?.priority_id}
                    onChange={(e) => handleSubmit(onSubmit(row?.id, e.target.value, "priority_id"))}
                    style={{
                        color: row?.priority_id == '1'
                            ? '#55CE63'
                            : row?.priority_id == '2'
                                ? 'orange'
                                : row?.priority_id == '3'
                                    ? 'red'
                                    : 'black',
                        border: '1px solid #ccc',
                        background: 'transparent',
                        padding: '5px 8px',
                        fontSize: '11px',
                        fontWeight: '500',
                        cursor: 'pointer',
                        borderRadius: '50px',
                    }}
                // option={priorities}
                >
                    {priorities.map((priority) => (
                        <option key={priority.value} value={priority.value}>
                            {priority.label}
                        </option>
                    ))}
                </select>
            ),
            sortable: true
        },
        {
            name: 'Due Date',
            selector: row => row?.due_date,
            sortable: true,
            center: true,
        },
        {
            name: 'Start Date',
            selector: row => row?.s_date,
            sortable: true,
            center: true,
        },
        {
            name: 'End Date',
            selector: row => row?.e_date,
            sortable: true,
            center: true,
        },
        {
            name: 'Task Status',
            center: true,
            selector: (row, index) => (
                <select
                    disabled={!isAllow.includes(175)}
                    value={row?.task_status_id}
                    onChange={(e) => handleSubmit(onSubmit(row?.id, e.target.value, "task_status_id"))}
                    style={{
                        color: row?.task_status_id == '1'
                            ? '#55CE63'
                            : row?.task_status_id == '2'
                                ? 'orange'
                                : row?.task_status_id == '3'
                                    ? 'red'
                                    : 'black',
                        border: '1px solid #ccc',
                        background: 'transparent',
                        padding: '5px 8px',
                        fontSize: '11px',
                        fontWeight: '500',
                        cursor: 'pointer',
                        borderRadius: '50px',
                    }}
                // option={priorities}
                >
                    {taskStatus.map((priority) => (
                        <option key={priority.value} value={priority.value}>
                            {priority.label}
                        </option>
                    ))}
                </select>
            ),
            sortable: true
        },
        {
            name: 'Status',
            center: true,
            selector: (row, index) => (
                <select
                    disabled={!isAllow.includes(175)}
                    value={row?.status}
                    onChange={(e) => handleStatusChange(row?.id, e.target.value)}
                    style={{
                        color: row?.status == '1' ? '#55CE63' : 'red',
                        border: '1px solid #ccc',
                        background: 'transparent',
                        padding: '5px 8px',
                        fontSize: '11px',
                        fontWeight: '500',
                        cursor: 'pointer',
                        borderRadius: '50px',
                    }}
                >
                    <option value="1">active</option>
                    <option value="0">inactive</option>
                </select>
            ),
            sortable: true
        },
        isAllow.includes(175) ||
            isAllow.includes(176) ? (
            {
                name: 'Action',
                center: true,
                selector: row => (
                    <div>
                        {isAllow.includes(175) ? (
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                onClick={() => {
                                    setSelectedClientId(row?.id);
                                    setEditModalShow(true);
                                }}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                        ) : (
                            <></>
                        )}
                        {isAllow.includes(176) ? (
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                onClick={() => {
                                    setSelectedClientId(row?.id);
                                    setDeleteModalShow(true);
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        ) : (
                            <></>
                        )}
                        <EditTaskModal
                            show={editModalShow}
                            onHide={() => setEditModalShow(false)}
                            id={selectedClientId}
                            getDataAll={getDataAll}
                        />
                        <ProjectDeleteModal
                            show={deleteModalShow}
                            onHide={() => setDeleteModalShow(false)}
                            handleDelete={handleDelete}
                            getDataAll={getDataAll}
                        />
                    </div>
                ),
                sortable: false
            }
        ) : (
            <></>
        )

    ];

    const conditionalRowStyles = [
        {
            when: row => row.id % 2 === 0,
            style: {
                backgroundColor: '#f2f2f2',
            },
        },
    ];

    return (
        <section className='datatable mt-4' data-aos="fade-up" data-aos-duration="3000">
            <DataTable
                columns={columns}
                data={data?.data?.data}
                pagination
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
            />
        </section>
    );
}

export default ProjectTaskTable;
