import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { faCalendarDays, faClipboard, } from '@fortawesome/free-regular-svg-icons'
import { faArrowDownWideShort, faCalendar, faCircleUser, faListUl, faPaperclip, faSearch, faTableCells, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Col, Form, InputGroup, Nav, Row, Tab } from 'react-bootstrap'
import './ProjectInnerPage.css'
import SummaryTab from './SummaryTab/SummaryTab'
import ListTab from './ListTab/ListTab'
import AttachementsTab from './AttachementsTab/AttachementsTab'
import CalendarTab from './CalendarTab/CalendarTab'
import BoardTab from './BoardTab/BoardTab'
import AddPeopleModal from './AddPeopleModal/AddPeopleModal'
import FilterTab from './FilterTab/FilterTab'
import { Link, useParams } from 'react-router-dom';
import { Context } from "../../../../utils/context";


const ProjectInnerPage = () => {

    const { id } = useParams();

    const { getData, postData, IMG_URL, deleteData, Select2Data, Per_Page_Dropdown, } = useContext(Context);
    const [data, setData] = useState([]);
    const [currentImage, setCurrentImage] = useState('');
    const [showModal, setShowModal] = useState(false);

    const [projects, setProjects] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [taskStatuses, setTaskStatuses] = useState([]);
    const [users, setUsers] = useState([]);



    const [search, setSearchName] = useState("");
    const [project, setProject] = useState();
    const [assign_to, setAssignTo] = useState();
    const [assign_by, setAssignBy] = useState();
    const [priority, setPriority] = useState();
    const [task_status, setTaskStatus] = useState();


    const getDataAll = async () => {

        const response = await getData(
            `/projectmanagement/task?term=${search}&project=${id}&priority=${priority || ""}` +
            `&assign_to=${assign_to || ""}&assign_by=${assign_by?.value || ""}&task_status=${task_status || ""}`
        );
        await setData(response);

    };
    console.log("taskkssssssss", data);

    useEffect(() => {
        getDataAll();
    }, [search, priority, project, assign_to, assign_by, task_status]);

    const GetAllProjects = async () => {
        const res = await getData("/project_management/allproject");
        if (res?.success) {
            setProjects(await Select2Data(res?.data, "project_id"))
        }
    };

    const GetAllPriorities = async () => {
        const res = await getData("/masters/all-priority-status");
        if (res?.success) {
            setPriorities(await Select2Data(res?.data, "priority_id"))
        }
    };

    const GetAllEmployees = async () => {
        const res = await getData("/masters/allemployees");
        if (res?.success) {
            setUsers(await Select2Data(res?.data, "user_id"));
        }
    };

    const GetAllTAskStatus = async () => {
        const res = await getData("/masters/all-task-status");
        if (res?.success) {
            setTaskStatuses(await Select2Data(res?.data, "task_status_id"))
        }
    };
    useEffect(() => {
        GetAllEmployees();
        GetAllProjects();
        GetAllPriorities();
        GetAllTAskStatus();
        getDataAll();
    }, []);

    const [showFilter, setShowFilter] = useState(false);

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };
    return (
        <section className='project-add project-inner'>
            <div className='main-title mt-4'>
                <h2>Projects Summary</h2>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item href="/project-add">Projects</Breadcrumb.Item>
                    <Breadcrumb.Item active>Project Summary</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col xl={8} lg={10} md={12} sm={12} xs={12}>
                        <div className="mobile-scroll-class">
                            <Nav variant="pills" className="flex-row justify-content-start">
                                <Nav.Item>
                                    <Nav.Link eventKey="first"><FontAwesomeIcon icon={faClipboard} className='me-1' /> Dashboard</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second"><FontAwesomeIcon icon={faTableCells} className='me-1' /> Board</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third"><FontAwesomeIcon icon={faListUl} className='me-1' /> List</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth"><FontAwesomeIcon icon={faCalendarDays} className='me-1' /> Calendar</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth"><FontAwesomeIcon icon={faPaperclip} /> Attachments</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </Col>
                    <Col xl={4} lg={2} md={12} sm={12} xs={12}>
                        <FilterTab
                            priority={priority}
                            assign_to={assign_to}
                            task_status={task_status}
                            setPriority={setPriority}
                            setAssignTo={setAssignTo}
                            setTaskStatus={setTaskStatus}
                        />
                    </Col>
                </Row>
                <div className='brown-border'></div>
                <Tab.Content className='mt-5'>
                    <Tab.Pane eventKey="first"><SummaryTab project_id={id} tasks={data} /></Tab.Pane>
                    <Tab.Pane eventKey="second"><BoardTab project_id={id} tasks={data} /></Tab.Pane>
                    <Tab.Pane eventKey="third"><ListTab project_id={id} tasks={data} getDataAll={getDataAll} /></Tab.Pane>
                    <Tab.Pane eventKey="fourth"><CalendarTab project_id={id} tasks={data} /></Tab.Pane>
                    <Tab.Pane eventKey="fifth"><AttachementsTab project_id={id} tasks={data} /></Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </section>
    )
}

export default ProjectInnerPage