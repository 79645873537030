import React, { useContext } from "react";
import { useState, useEffect } from "react";
import './ProjectAdd.css'
import { Col, Form, Row, Dropdown, DropdownButton } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBorderAll, faPlus } from '@fortawesome/free-solid-svg-icons';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ProjectAddModal from './ProjectAddModal/ProjectAddModal';
import CreateGridView from './CreateGridView';
import CreateListView from './CreateListView';
import { Context } from "../../../utils/context";
import ProjectListTable from "./ProjectListTable";

const options = [
    { value: 'Ecommerce', label: 'Ecommerce' },
    { value: 'Banking', label: 'Banking' },
    { value: 'Infomative', label: 'Infomative' },
];
const ProjectAdd = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [tls, setTls] = useState([]);
    const [clients, setClients] = useState([]);
    const [taskStatuses, setTaskStatuses] = useState([]);

    const { getData, postData, IMG_URL, deleteData, Select2Data, isAllow } = useContext(Context);
    const [data, setData] = useState();
    const [search, setSearchName] = useState("");
    const [team_leader, setTeamLeader] = useState();
    const [client, setClient] = useState();
    const [task_status, setTaskStatus] = useState();

    const getDataAll = async () => {
        const response = await getData(
            `/projectmanagement/project?term=${search}&client=${client?.value || ""}&team_leader=${team_leader?.value || ""}` +
            `&project_status=${task_status?.value || ""}`
        );
        await setData(response);
    };
    useEffect(() => {
        getDataAll();
    }, [modalShow, search, team_leader, client, task_status]);

    const GetAllEmployees = async () => {
        const res = await getData("/masters/allemployees");
        if (res?.success) {
            // setTls(await Select2Data(res?.data, "team_leader_id"));
            // setUsers(await Select2Data(res?.data, "user_id"));
        }
    };

    const GetAllTls = async () => {
        const res = await getData("/masters/allteamleaders");
        if (res?.success) {
            setTls(await Select2Data(res?.data, "team_leader_id"));
        }
    };
    const GetAllClient = async () => {
        const res = await getData("/project_management/allclients");
        if (res?.success) {
            setClients(await Select2Data(res?.data, "client_id"))
        }
    };

    const GetAllTAskStatus = async () => {
        const res = await getData("/masters/all-task-status");
        if (res?.success) {
            setTaskStatuses(await Select2Data(res?.data, "task_status_id"))
        }
    };
    useEffect(() => {
        GetAllEmployees();
        GetAllTls();
        GetAllClient();
        GetAllTAskStatus();
        getDataAll();

    }, []);

    return (
        <section className='project-add'>
            <div className='d-flex justify-content-between'>
                <div className='main-title'>
                    <h2>Projects</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Project</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {isAllow.includes(170) ? (
                    <div className=''>
                        <button className='create-project-btn mt-2' onClick={() => setModalShow(true)}><FontAwesomeIcon icon={faPlus} className='me-1 mt-1' /> Create Project</button>
                        <ProjectAddModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <Row>
                <Col xl={2} lg={3} md={6} sm={12} xs={12} >
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            {/* <Form.Label>Project Name</Form.Label> */}
                            <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="Project Name"
                                value={search}
                                onChange={(e) => {
                                    setSearchName(e.target.value);
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xl={2} lg={3} md={6} sm={12} xs={12} >
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            {/* <Form.Label>Employee Name</Form.Label> */}
                            <Select
                                isSearchable
                                options={tls}
                                value={team_leader}
                                placeholder="Project Leader"
                                onChange={(e) => {
                                    setTeamLeader(e);
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    <Select
                        isSearchable
                        options={clients}
                        value={client}
                        placeholder="Client"
                        onChange={(e) => {
                            setClient(e);
                        }}
                    />
                </Col>

                {/* <Col lg={2} md={6} sm={12} xs={12}>
                    <Select
                        isSearchable
                        options={taskStatuses}
                        value={task_status}
                        placeholder="Task Status"
                        onChange={(e) => {
                            setTaskStatus(e);
                        }}
                    />
                </Col> */}

                {/* <Col lg={2} md={4} sm={8} xs={8}>
                    <button
                        className='search-button mt-3  mt-md-0'
                        onClick={getDataAll}
                    >Search</button>


                </Col> */}
                <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    <button
                        className='search-button mt-3 mt-md-0'
                        onClick={async () => {
                            setSearchName("");
                            setClient("");
                            setTeamLeader("");
                            setTaskStatus("")
                            // await getDataAll();
                        }}
                    >
                        Clear
                    </button></Col>
            </Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills" className="flex-row justify-content-end mb-2 ">
                    <Nav.Item>
                        <Nav.Link eventKey="first"><FontAwesomeIcon icon={faBorderAll} /></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="second"><FontAwesomeIcon icon={faBars} /></Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <CreateGridView
                            data={data}
                            getDataAll={getDataAll}

                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                        {/* <ProjectListTable

                            data={data}
                            getDataAll={getDataAll}
                        /> */}

                        < CreateListView
                            data={data}
                            getDataAll={getDataAll}
                        />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>

        </section >
    )
}

export default ProjectAdd