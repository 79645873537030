import React, { useContext } from "react";
import { useState, useEffect } from "react";
import './ProjectClient.css'
import { Col, Form, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faPenToSquare, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import ProjectClientEditModal from './ProjectClientModal/ProjectClientEditModal';
import ProjectDeleteClientModal from './ProjectClientModal/ProjectDeleteClientModal';
import { Context } from "../../../utils/context";


const clients = [
    {
        id: 1,
        companyName: 'Global Technologies',
        clientName: 'Barry Cuda',
        designation: 'CEO',
        image: '/assets/Image/photos/avatar-06.jpg',
    },
    {
        id: 2,
        companyName: 'Delta Infotech',
        clientName: 'Barry Cuda',
        designation: 'Manager',
        image: '/assets/Image/photos/avatar-07.jpg',
    },
    {
        id: 3,
        companyName: 'Cream Inc',
        clientName: 'Barry Cuda',
        designation: 'Co-Manager',
        image: '/assets/Image/photos/avatar-13.jpg',
    },
    {
        id: 4,
        companyName: 'Wellware Company',
        clientName: 'Barry Cuda',
        designation: 'Director',
        image: '/assets/Image/photos/avatar-14.jpg',
    },
    {
        id: 5,
        companyName: 'Global Technologies',
        clientName: 'Barry Cuda',
        designation: 'CEO',
        image: '/assets/Image/photos/avatar-06.jpg',
    },
    {
        id: 6,
        companyName: 'Delta Infotech',
        clientName: 'Barry Cuda',
        designation: 'Manager',
        image: '/assets/Image/photos/avatar-07.jpg',
    },
    {
        id: 7,
        companyName: 'Cream Inc',
        clientName: 'Barry Cuda',
        designation: 'Co-Manager',
        image: '/assets/Image/photos/avatar-13.jpg',
    },
    {
        id: 8,
        companyName: 'Wellware Company',
        clientName: 'Barry Cuda',
        designation: 'Director',
        image: '/assets/Image/photos/avatar-14.jpg',
    },
];

const ProjectGridView = ({ data, getDataAll }) => {
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [editmodalShow, setEditModalShow] = useState(false);
    const [deletemodalShow, setDeleteModalShow] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState("");

    const { isAllow, IMG_URL, deleteData } = useContext(Context);

    const toggleDropdown = (clientId) => {
        setDropdownVisible(dropdownVisible === clientId ? null : clientId);
    };

    const handleDelete = async () => {
        const response = await deleteData(`/projectmanagement/client/${selectedClientId}`);
        getDataAll();
    };

    return (
        <section >
            {/* Gridview */}
            <Row>
                {data?.data?.data?.map(client => (
                    <Col xl={3} lg={4} md={6} sm={6} xs={12} key={client.id}>
                        <div className='white-bggg'>
                            {
                                isAllow.includes(179) || isAllow.includes(180) ? (
                                    client?.status === '1' && (
                                        <div className='text-end'>
                                            <FontAwesomeIcon
                                                icon={faEllipsisVertical}
                                                className='ellips-img'
                                                onClick={() => toggleDropdown(client.id)}
                                            />
                                        </div>
                                    )
                                ) : (
                                    <></>
                                )
                            }

                            {dropdownVisible === client.id && (
                                <div className='drop-down-bgg'>
                                    {isAllow.includes(179) ? (
                                        <p className='client-designation' onClick={() => setEditModalShow(true)} ><FontAwesomeIcon icon={faPenToSquare} /> Edit</p>
                                    ) : (
                                        <></>
                                    )}
                                    <ProjectClientEditModal
                                        show={editmodalShow}
                                        onHide={() => setEditModalShow(false)}
                                        id={client?.id}
                                        getDataAll={getDataAll}
                                    />
                                    {isAllow.includes(180) ? (
                                        <p className='client-designation'
                                            onClick={() => {
                                                setSelectedClientId(client?.id);
                                                setDeleteModalShow(true);
                                            }}
                                        ><FontAwesomeIcon icon={faTrashCan} /> Delete</p>
                                    ) : (
                                        <></>
                                    )}
                                    <ProjectDeleteClientModal
                                        show={deletemodalShow}
                                        onHide={() => setDeleteModalShow(false)}
                                        handleDelete={handleDelete}
                                        getDataAll={getDataAll}
                                    />
                                </div>
                            )}
                            <div>
                                <img src={IMG_URL + client?.image} className="client-img" alt={client?.name} />

                                <h5 className='company-name'>{client?.company_name}</h5>
                                <span className='client-designation'>{client?.client_id}</span>
                                <p className='client-name'>{client?.name}</p>
                                <span className='client-designation'>{client?.designation}</span>

                                {/* <div>
                                    <button className='message-btn me-2'>Message</button>
                                    <button className='message-btn'>View Profile</button>
                                </div> */}
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </section>
    )
}

export default ProjectGridView;
