import React, { useContext } from "react";
import { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditUserModal from './EditUserModal';
import DeleteUserModal from './DeleteUserModal';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { faCircleDot, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useForm, Controller } from "react-hook-form";

import { Context } from "../../../../../utils/context";



const customStyles = {
    headCells: {
        style: {
            fontWeight: "bolder",
            fontSize: "16px",
            borderBottom: "1px solid #000",
            borderRight: "0",
        },
    },
    rows: {
        style: {
            backgroundColor: '#ffffff',
            border: '1px solid #000',
            color: "#000",
            borderBottom: "0",
            borderTop: "0",
            borderRight: "0",
            borderLeft: "0",
        },
    },
    cells: {
        style: {
            borderBottom: "0",
            borderTop: "0",
            width: "100%",
            display: "grid",
            borderRight: "0",
            textAlign: 'center'
        },
    },
};

const conditionalRowStyles = [
    {
        when: row => row.id % 2 === 0,
        style: {
            backgroundColor: '#f2f2f2',
        },
    },
];

const AddUserList = () => {

    const {
        getData,
        editStatusData,
        deleteData,
        ErrorNotify,
        isAllow,
        Per_Page_Dropdown,
        IMG_URL,
        postData,
    } = useContext(Context);

    const [editCell, setEditCell] = useState(null);
    const [editValue, setEditValue] = useState("");
    const [selectedClientId, setSelectedClientId] = useState("");
    const [editmodalShow, setEditModalShow] = useState(false);
    const [deletemodalShow, setDeleteModalShow] = useState(false);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        const response = await getData(
            `/employee/employee_details`
        );
        await setData(response);
    };
    useEffect(() => {
        getDataAll();
    }, []);

    const ChangeStatus = async (id) => {
        const response = await editStatusData(`/employee/employee_details/${id}`);
        // setChangeStatus(response);
    };

    const handleStatusChange = async (id, newStatus) => {
        const data = {
            status: newStatus,
        }
        await postData(`/employee/employee_details/status/${id}`, data);
        getDataAll();
    };

    const handleDelete = async () => {
        const response = await deleteData(`/employee/employee_details/${selectedClientId}`);
        setDeleteModalShow(false);
        getDataAll();
    };

    const columns = [
        {
            name: 'User Image',
            selector: row => (
                <img src={IMG_URL + row?.image} alt={row?.name} width={50} height={50} style={{ borderRadius: '50%', margin: '6px' }} />
            ),
            sortable: true,
            center: true,
        },
        {
            name: 'User Name',
            selector: row => row?.name,
            sortable: true,
            center: true,
        },
        {
            name: 'User Role',
            selector: row => row?.userroles[0]?.role?.name,
            sortable: true,
            center: true,
        },
        {
            name: 'Email',
            selector: row => row?.email,
            sortable: true,
            center: true,
        },
        {
            name: 'Mobile',
            selector: row => row?.mobile_number,
            sortable: true,
            center: true,
        },
        {
            name: 'Status',
            center: true,
            selector: (row, index) => (
                row?.id === 1 || row?.id === 2 || row.id === 3 ? (
                    <></>
                ) : (
                    <select
                        disabled={!isAllow.includes(171)}
                        value={row?.status}
                        onChange={(e) => handleStatusChange(row?.id, e.target.value)}
                        style={{
                            color: row?.status == '1' ? '#55CE63' : 'red',
                            border: '1px solid #ccc',
                            background: 'transparent',
                            padding: '5px 10px',
                            fontSize: '14px',
                            fontWeight: '500',
                            cursor: 'pointer',
                            borderRadius: '50px',
                        }}
                    >
                        <option value="1">active</option>
                        <option value="0">inactive</option>
                    </select>
                )
            ),
            sortable: true
        },
        {
            name: 'Action',
            center: true,
            selector: row => (
                <div>
                    {row?.id === 1 || row?.id === 2 || row?.id === 3 ? (
                        <></>
                    ) : (
                        <>
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                onClick={() => setEditModalShow(true)}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                onClick={() => {
                                    setSelectedClientId(row?.id);
                                    setDeleteModalShow(true);
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        </>
                    )}
                    <EditUserModal
                        show={editmodalShow}
                        onHide={() => setEditModalShow(false)}
                        id={row?.id}
                        getDataAll={getDataAll}
                    />
                    <DeleteUserModal
                        show={deletemodalShow}
                        onHide={() => setDeleteModalShow(false)}
                        handleDelete={handleDelete}
                        getDataAll={getDataAll}
                    />
                </div>
            ),
            sortable: false
        }

    ];



    useEffect(() => {
        AOS.init({ once: true });
    }, []);

    return (
        <section className='datatable mt-3' data-aos="fade-up"
            data-aos-duration="3000">
            <DataTable
                columns={columns}
                data={data?.data?.data}
                pagination
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
            />
        </section>
    )
}

export default AddUserList;
