import React, { useContext } from "react";
import { useState, useEffect } from "react";
import './ProjectClient.css';
import DataTable from 'react-data-table-component';
import ProjectClientEditModal from './ProjectClientModal/ProjectClientEditModal';
import ProjectDeleteClientModal from './ProjectClientModal/ProjectDeleteClientModal';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ProjectListClient from './ProjectClientModal/ProjectListClient';
import { Context } from "../../../utils/context";


// teamData
export const teamData = [
    {
        id: 1,
        photo: '/assets/Image/photos/avatar-14.jpg',
        name: 'John Doe',
        clientid: '0123456',
        contactperson: 'abc',
        email: 'demo@gmail.com',
        mobile: '7896541232',
        project: "Project Management",
        status: 'active',
        action: 'edit',
        designation: 'Project Manager',
        totalHoursLogged: 120,
        totalAssignedTasks: 10
    },
    {
        id: 2,
        photo: '/assets/Image/photos/avatar-13.jpg',
        name: 'John Doe',
        clientid: '0123456',
        contactperson: 'abc',
        email: 'demo@gmail.com',
        mobile: '7896541232',
        project: "Project Management",
        status: 'inactive',
        action: 'edit',
        designation: 'Project Manager',
        totalHoursLogged: 120,
        totalAssignedTasks: 10
    },
    {
        id: 3,
        photo: '/assets/Image/photos/avatar-18.jpg',
        name: 'John Doe',
        clientid: '0123456',
        contactperson: 'abc',
        email: 'demo@gmail.com',
        mobile: '7896541232',
        project: "Project Management",
        status: 'active',
        action: 'edit',
        designation: 'Project Manager',
        totalHoursLogged: 120,
        totalAssignedTasks: 10
    },
    {
        id: 4,
        photo: '/assets/Image/photos/avatar-19.jpg',
        name: 'Jane Smith',
        clientid: '0123456',
        contactperson: 'abc',
        email: 'demo@gmail.com',
        mobile: '7896541232',
        project: "Project Management",
        status: 'inactive',
        action: 'edit',
        designation: 'Project Manager',
        totalHoursLogged: 120,
        totalAssignedTasks: 10
    },
    {
        id: 5,
        photo: '/assets/Image/photos/avatar-18.jpg',
        name: 'Jane Smith',
        clientid: '0123456',
        contactperson: 'abc',
        email: 'demo@gmail.com',
        mobile: '7896541232',
        project: "Project Management",
        status: 'active',
        action: 'edit',
        designation: 'Project Manager',
        totalHoursLogged: 120,
        totalAssignedTasks: 10
    },
    {
        id: 6,
        photo: '/assets/Image/photos/avatar-18.jpg',
        name: 'Jane Smith',
        clientid: '0123456',
        contactperson: 'abc',
        email: 'demo@gmail.com',
        mobile: '7896541232',
        project: "Project Management",
        status: 'inactive',
        action: 'edit',
        designation: 'Project Manager',
        totalHoursLogged: 120,
        totalAssignedTasks: 10
    },
];

const customStyles = {
    headCells: {
        style: {
            fontWeight: "bolder",
            fontSize: "16px",
            borderBottom: "1px solid #000",
            textAlign: "center",
            borderRight: "0",
            textAlign: 'center'
        },
    },
    rows: {
        style: {
            backgroundColor: '#ffffff',
            border: '1px solid #000',
            color: "#000",
            borderBottom: "0",
            borderTop: "0",
            borderRight: "0",
            borderLeft: "0",
        },
    },
    cells: {
        style: {
            borderBottom: "0",
            borderTop: "0",
            width: "100%",
            display: "grid",
            borderRight: "0",
            textAlign: 'center'
        },
    },
};

const conditionalRowStyles = [
    {
        when: row => row.id % 2 === 0,
        style: {
            backgroundColor: '#f2f2f2',
        },
    },
];

const ProjectListView = ({ data, getDataAll }) => {

    const { isAllow, postData, IMG_URL, deleteData } = useContext(Context);
    const [editModalShow, setEditModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [selectedClientId, setSelectedClientId] = useState("");

    const handleStatusChange = async (id, newStatus) => {
        const data = {
            status: newStatus,
        }
        await postData(`/projectmanagement/client/status/${id}`, data);
        getDataAll();
    };

    const handleDelete = async () => {
        const response = await deleteData(`/projectmanagement/client/${selectedClientId}`);
        if (response?.success) {
            setDeleteModalShow(false)
        }
        getDataAll();
    };

    useEffect(() => {
        getDataAll();
    }, [editModalShow]);

    const columns = [
        {
            name: 'Client Image',
            selector: row => <img src={IMG_URL + row?.image} alt={row?.name} width={50} height={50} style={{ borderRadius: '50%', margin: '6px' }} />,
            sortable: true,
            center: 'true',
        },
        {
            name: 'Client Name',
            selector: row => row?.name,
            sortable: true,
            center: 'true',
        },
        {
            name: 'Client Id',
            selector: row => row?.client_id,
            sortable: true,
            center: 'true',
        },
        {
            name: 'Email',
            selector: row => row?.email,
            sortable: true,
            center: 'true',
        },
        {
            name: 'Mobile',
            selector: row => row?.contact_no,
            sortable: true,
            center: 'true',
        },
        {
            name: 'Project List',
            selector: row => <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <p>
                    {row?.projects?.map((project) => project?.name).join(", ")}
                </p>
                {row?.projects?.length > 0 &&
                    <p className='project-count-number ms-1' onClick={() => setModalShow(true)}>
                        {row?.projects?.length > 1 ? row?.projects?.length - 1 : row?.projects?.length}
                        {row?.projects?.length > 1 && < FontAwesomeIcon icon={faPlus} />}
                    </p>
                }

                <ProjectListClient
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    data={row?.projects}
                />
            </div>,
            sortable: true,
            center: true,

        },
        {
            name: 'Status',
            center: 'true',
            selector: (row, index) => (
                <select
                    disabled={!isAllow.includes(179)}
                    value={row?.status}
                    onChange={(e) => handleStatusChange(row?.id, e.target.value)}
                    style={{

                        color: row?.status == '1' ? '#55CE63' : 'red',
                        border: '1px solid #ccc',
                        background: 'transparent',
                        padding: '5px 10px',
                        fontSize: '14px',
                        fontWeight: '500',
                        cursor: 'pointer',
                        borderRadius: '50px',
                    }}
                >
                    <option value="1">active</option>
                    <option value="0">inactive</option>
                </select>
            ),
            sortable: true
        },
        isAllow.includes(179) ||
            isAllow.includes(180) ? (
            {
                name: 'Action',
                center: 'true',
                selector: row => (
                    <div>
                        {row?.status == '1' && (
                            <>
                                {isAllow.includes(179) && (
                                    <FontAwesomeIcon
                                        icon={faPenToSquare}
                                        onClick={() => {
                                            setSelectedClientId(row?.id);
                                            setEditModalShow(true);
                                        }}
                                        style={{ cursor: 'pointer', marginRight: '10px' }}
                                    />
                                )}

                                {isAllow.includes(180) && (
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        onClick={() => {
                                            setSelectedClientId(row?.id);
                                            setDeleteModalShow(true);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}
                            </>
                        )}

                        <ProjectClientEditModal
                            show={editModalShow}
                            onHide={() => setEditModalShow(false)}
                            id={selectedClientId}
                            getDataAll={getDataAll}
                        />

                        <ProjectDeleteClientModal
                            show={deleteModalShow}
                            onHide={() => setDeleteModalShow(false)}
                            handleDelete={handleDelete}
                            getDataAll={getDataAll}
                        />
                    </div>

                ),
                sortable: false
            }
        ) : (
            <></>
        )

    ];



    useEffect(() => {
        AOS.init({ once: true });
    }, []);

    return (
        <section className='datatable' data-aos="fade-up"
            data-aos-duration="3000">
            <DataTable
                columns={columns}
                data={data?.data?.data}
                pagination
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
            />
        </section>
    )
}

export default ProjectListView;
