import React, { useContext } from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import JoditEditor from 'jodit-react';
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import ModalSave from "../../saveModal/ModalSave";
import { Row, Col, Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";

const ProjectEditModal = (props) => {

    const id = props?.id;
    console.log("idddd", id);

    const editor = useRef(null);
    const [content, setContent] = useState('');
    const placeholder = 'Start typing...';
    const config = useMemo(() => ({
        readonly: false,
        placeholder: placeholder
    }), [placeholder]);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        watch,
        getValues,
        reset,
    } = useForm();

    const [showModal, setShowModal] = useState({
        code: 0,
        message: "",
    });

    const endDate = watch('e_date');
    const startDate = watch('s_date');
    const documents = watch('document');
    const today = new Date().toISOString().split('T')[0];

    const { getData, postData, Select2Data } = useContext(Context);

    const [clients, setClients] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [tls, setTls] = useState([]);
    const [users, setUsers] = useState([]);

    const onSubmit = async (data) => {
        try {
            console.log(data);
            const sendData = new FormData();
            sendData.append("name", data?.name);
            sendData.append("s_date", data?.s_date);
            sendData.append("e_date", data?.e_date);
            sendData.append("budget", data?.budget);
            sendData.append("progress", data?.progress);
            sendData.append("team_leader_id", data?.team_leader_id?.value);
            sendData.append("client_id", data?.client_id?.value);
            sendData.append("description", data?.description);
            sendData.append("priority_id", data?.priority_id?.value);
            sendData.append("document", data?.document[0]);
            data?.user_id?.forEach((user) => {
                sendData.append("user_id", user?.value);
            });
            const response = await postData(`/projectmanagement/project/${id}`, sendData);
            console.log(response);
            if (response?.success) {
                setShowModal({ code: response?.code, message: response.message });
                setTimeout(() => {
                    setShowModal(0);
                    props.onHide();
                    props.getDataAll();
                    reset();
                }, 3000);
            } else {
                setShowModal({ code: response?.code, message: response.message });
                setTimeout(() => {
                    setShowModal(0);
                    props.onHide();
                    props.getDataAll();
                    reset();
                }, 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const GetAllClient = async () => {
        const res = await getData("/project_management/allclients");
        if (res?.success) {
            setClients(await Select2Data(res?.data, "client_id"))
        }
    };

    const GetAllPriorities = async () => {
        const res = await getData("/masters/all-priority-status");
        if (res?.success) {
            setPriorities(await Select2Data(res?.data, "priority_id"))
        }
    };

    const GetAllEmployees = async () => {
        const res = await getData("/masters/allemployees");
        if (res?.success) {
            setUsers(await Select2Data(res?.data, "user_id"));
        }
    };

    const GetAllTeamLeaders = async () => {
        const res = await getData("/masters/allteamleaders");
        if (res?.success) {
            setTls(await Select2Data(res?.data, "team_leader_id"));
        }
    };

    const GetEditData = async () => {
        const response = await getData(`/projectmanagement/project/${id}`);
        reset(response?.data)
        reset({
            ...response?.data,
            user_id: response?.data?.team_members,
            // other fields if needed
        });
    };

    useEffect(() => {
        GetAllClient();
        GetAllPriorities();
        GetAllEmployees();
        GetAllTeamLeaders();
    }, []);
    useEffect(() => {
        GetEditData();
    }, [id]);

    return (
        <section >
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className='project-client-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Project
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Project Name</Form.Label>
                                    <Form.Control
                                        className={classNames("", {
                                            "is-invalid": errors?.name,
                                        })}
                                        type="text"
                                        {...register("name", {
                                            required: "Name is required",
                                        })}
                                        placeholder="Name"
                                        onKeyDown={(event) => {
                                            const { key } = event;
                                            if (!/^[A-Za-z\s]+$/.test(key) && key !== "Backspace" && key !== "Tab") {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors.name && (
                                        <span className="text-danger">
                                            {errors.name.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Client</Form.Label>
                                    <Controller
                                        name="client_id" // name of the field
                                        {...register("client_id", {
                                            required: "Course  is required",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.client_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={clients}
                                            />
                                        )}
                                    />

                                    {errors.client_id && (
                                        <span className="text-danger">
                                            {errors.client_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        min={getValues("createdAt") ? new Date(getValues("createdAt")).toISOString().split("T")[0] : null}
                                        max={endDate}
                                        {...register("s_date", { required: "Start Date is required" })}
                                    />
                                    {errors.s_date && (
                                        <span className="text-danger">
                                            {errors.s_date.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register("e_date", {
                                            required: "End Date is required",

                                            // validate: value => {
                                            //     const sDate = getValues("s_date");
                                            //     return !sDate || value >= sDate || "End Date should be greater than Start Date";
                                            // }
                                        })}
                                        min={startDate ? new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1)).toISOString().split("T")[0] : today}
                                    />
                                    {errors.e_date && (
                                        <span className="text-danger">
                                            {errors.e_date.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Priority </Form.Label>

                                    <Controller
                                        name="priority_id" // name of the field
                                        {...register("priority_id", {
                                            required: "Course  is required",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.priority_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={priorities}
                                            />
                                        )}
                                    />

                                    {errors.priority_id && (
                                        <span className="text-danger">
                                            {errors.priority_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Budget </Form.Label>
                                    <Form.Control
                                        className={classNames("", {
                                            "is-invalid": errors?.budget,
                                        })}
                                        type="number"
                                        min={0}
                                        step={500}
                                        {...register("budget", {
                                            // required: "Budget is required",
                                            validate: value => value > 0 || "Budget should not be 0 or negative"
                                        })}
                                        placeholder="Budget"
                                        onKeyDown={(event) => {
                                            if (event.key === '-' || event.key === 'e') {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors.budget && (
                                        <span className="text-danger">
                                            {errors.budget.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Project Leader </Form.Label>
                                    <Controller
                                        name="team_leader_id" // name of the field
                                        {...register("team_leader_id", {
                                            required: "Team Leader is required",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.team_leader_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={tls}
                                            />
                                        )}
                                    />

                                    {errors.team_leader_id && (
                                        <span className="text-danger">
                                            {errors.team_leader_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            {/* <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Upload Team Leader Image </Form.Label>
                                    <Form.Control type="file" />
                                </Form.Group>
                            </Col> */}
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Team Member</Form.Label>
                                    <Controller
                                        name="user_id" // name of the field
                                        {...register("user_id", {
                                            required: "Team Member is required",
                                        })}
                                        control={control}

                                        render={({ field }) => (
                                            <Select
                                                isMulti
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.user_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={users}
                                            />
                                        )}
                                    />

                                    {errors.user_id && (
                                        <span className="text-danger">
                                            {errors.user_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Upload Document </Form.Label>
                                    <Form.Group>
                                        <Form.Control
                                            className={classNames("", {
                                                "is-invalid": errors?.document,
                                                // "is-valid": !errors?.document && getValues("document"),
                                            })}
                                            type="file"

                                            {...register("document", {
                                                // required: "documents is required",
                                            })}

                                            accept="image/*"
                                        />

                                    </Form.Group>
                                    {errors.document && (
                                        <span className="text-danger">
                                            {errors.document.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Label></Form.Label>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    {getValues("document") && (
                                        <span>
                                            {typeof getValues("document") === 'string'
                                                ? getValues("document").split('/').pop() // If it's a string (existing file path), display file name
                                                : getValues("document") instanceof File
                                                    ? getValues("document").name // If it's a File object, display the file name
                                                    : null}
                                        </span>
                                    )}

                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Label>Project Description </Form.Label>
                                <Controller
                                    name="description" // Provide the field name
                                    control={control} // Pass the control object from useForm()
                                    rules={{ required: "Description is required" }} // Validation rules
                                    render={({ field }) => (
                                        <JoditEditor
                                            value={field?.value}
                                            onChange={(newContent) =>
                                                field.onChange(newContent)
                                            }
                                            onBlur={field.onBlur}
                                            className={classNames("", {
                                                "is-invalid": !!errors.description,
                                            })}
                                            placeholder="description"
                                        />
                                    )}
                                />
                                {errors.description && (
                                    <span className="text-danger">
                                        {errors.description.message}
                                    </span>
                                )}
                            </Col>
                        </Row>
                        <Modal.Footer>
                            <div className='mx-auto'>
                                <Button
                                    // onClick={props.onHide}
                                    className='submit-btn me-2'
                                    type="submit"
                                >
                                    <FontAwesomeIcon icon={faFloppyDisk} /> Save
                                </Button>
                                <Button
                                    onClick={props.onHide}
                                    className='submit-btn'
                                >
                                    <FontAwesomeIcon icon={faXmark} /> Cancel
                                </Button>
                            </div>
                            <ModalSave
                                message={showModal.message}
                                showErrorModal={showModal.code ? true : false}
                            />
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal>
        </section>
    )
}

export default ProjectEditModal

