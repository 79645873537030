import React, { useContext } from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JoditEditor from 'jodit-react';
import Select from 'react-select';
import ModalSave from "../../saveModal/ModalSave";
import { Row, Col, Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";

const EditTaskModal = (props) => {
    const placeholder = 'Start typing...';
    const config = useMemo(() => ({
        readonly: false,
        placeholder: placeholder
    }), [placeholder]);

    const id = props?.id;

    const [showModal, setShowModal] = useState({
        code: 0,
        message: "",
    });


    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        watch,
        getValues,
        reset,
    } = useForm();
    const endDate = watch('e_date');
    const startDate = watch('s_date');
    const dueDate = watch('due_date');
    const today = new Date().toISOString().split('T')[0];

    const { getData, postData, Select2Data } = useContext(Context);

    const [projects, setProjects] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [users, setUsers] = useState([]);

    const onSubmit = async (data) => {
        try {
            console.log(data);
            const sendData = new FormData();
            sendData.append("name", data?.name);
            sendData.append("s_date", data?.s_date);
            sendData.append("e_date", data?.e_date);
            sendData.append("due_date", data?.due_date);
            sendData.append("description", data?.description);
            sendData.append("project_id", data?.project_id?.value);
            sendData.append("priority_id", data?.priority_id?.value);
            sendData.append("assign_to_id", data?.assign_to_id?.value);
            sendData.append("attachment", data?.attachment[0]);
            const response = await postData(`/projectmanagement/task/${id}`, sendData);
            console.log(response);
            if (response?.success) {
                setShowModal({ code: response?.code, message: response.message });
                setTimeout(() => {
                    setShowModal(0);
                    props.onHide();
                    props.getDataAll();
                    reset();
                }, 3000);
            } else {
                setShowModal({ code: response?.code, message: response.message });
                setTimeout(() => {
                    setShowModal(0);
                    props.onHide();
                    props.getDataAll();
                    reset();
                }, 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const GetAllProjects = async () => {
        const res = await getData("/project_management/allproject");
        if (res?.success) {
            setProjects(await Select2Data(res?.data, "project_id"))
        }
    };

    const GetAllPriorities = async () => {
        const res = await getData("/masters/all-priority-status");
        if (res?.success) {
            setPriorities(await Select2Data(res?.data, "priority_id"))
        }
    };

    const GetAllEmployees = async () => {
        const res = await getData("/masters/allMembers");
        if (res?.success) {
            setUsers(await Select2Data(res?.data, "user_id"));
        }
    };

    useEffect(() => {
        GetAllProjects();
        GetAllPriorities();
        GetAllEmployees();
    }, []);

    const GetEditData = async () => {
        const res = await getData(`/projectmanagement/task/${id}`);
        if (res?.success) {
            reset(res?.data)
        }
    };

    useEffect(() => {
        GetEditData()
    }, [id]);


    return (
        <section >
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='project-client-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Task
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Project</Form.Label>
                                    <Controller
                                        name="project_id" // name of the field
                                        {...register("project_id", {
                                            required: "Project is required",
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.project_id,
                                            "is-valid": getValues("project_id"),
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.project_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={projects}
                                            />
                                        )}
                                    />

                                    {errors.project_id && (
                                        <span className="text-danger">
                                            {errors.project_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Task Title</Form.Label>
                                    <Form.Control
                                        className={classNames("", {
                                            "is-invalid": errors?.name,
                                            "is-valid": getValues("name"),
                                        })}
                                        type="text"
                                        {...register("name", {
                                            required: "Title is required",
                                        })}
                                        placeholder="Title..."
                                    />
                                    {errors.name && (
                                        <span className="text-danger">
                                            {errors.name.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Assigned To</Form.Label>
                                    <Controller
                                        name="assign_to_id" // name of the field
                                        {...register("assign_to_id", {
                                            required: "Employee is required",
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.assign_to_id,
                                            "is-valid": getValues("assign_to_id"),
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.assign_to_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={users}
                                            />
                                        )}
                                    />

                                    {errors.assign_to_id && (
                                        <span className="text-danger">
                                            {errors.assign_to_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Due Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        min={endDate}
                                        {...register("due_date", { required: "Due Date is required" })}
                                    />
                                    {errors.due_date && (
                                        <span className="text-danger">
                                            {errors.due_date.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        // min={today}
                                        max={endDate}
                                        {...register("s_date", { required: "Start Date is required" })}
                                    />
                                    {errors.s_date && (
                                        <span className="text-danger">
                                            {errors.s_date.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register("e_date", {
                                            required: "End Date is required",
                                        })}
                                        min={startDate ? startDate : today}
                                        max={dueDate}
                                    />
                                    {errors.e_date && (
                                        <span className="text-danger">
                                            {errors.e_date.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Priority</Form.Label>
                                    <Controller
                                        name="priority_id" // name of the field
                                        {...register("priority_id", {
                                            required: "Course  is required",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.priority_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={priorities}
                                            />
                                        )}
                                    />
                                    {errors.priority_id && (
                                        <span className="text-danger">
                                            {errors.priority_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Task Attachement</Form.Label>
                                    <Form.Group>
                                        <Form.Control
                                            className={classNames("", {
                                                "is-invalid": errors?.attachment,
                                                "is-valid": !errors?.attachment && getValues("attachment"),
                                            })}
                                            type="file"

                                            {...register("attachment", {
                                                // required: "attachment is required",
                                            })}

                                            accept="image/*"
                                        />
                                    </Form.Group>
                                    {errors.attachment && (
                                        <span className="text-danger">
                                            {errors.attachment.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Label>Project Description </Form.Label>
                                <Controller
                                    name="description" // Provide the field name
                                    control={control} // Pass the control object from useForm()
                                    rules={{ required: "Description is required" }} // Validation rules
                                    render={({ field }) => (
                                        <JoditEditor
                                            value={field?.value}
                                            onChange={(newContent) =>
                                                field.onChange(newContent)
                                            }
                                            onBlur={field.onBlur}
                                            className={classNames("", {
                                                "is-invalid": !!errors.description,
                                            })}
                                            placeholder="description"
                                        />
                                    )}
                                />
                                {errors.description && (
                                    <span className="text-danger">
                                        {errors.description.message}
                                    </span>
                                )}
                            </Col>
                        </Row>
                        <Modal.Footer>
                            <div className='mx-auto'>
                                <button
                                    // onClick={props.onHide}
                                    type="submit"
                                    className='submit-btn me-2'
                                >
                                    <FontAwesomeIcon icon={faFloppyDisk} /> Save
                                </button>
                                <button onClick={props.onHide} className='submit-btn'>
                                    <FontAwesomeIcon icon={faXmark} /> Cancel
                                </button>
                            </div>
                            <ModalSave
                                message={showModal.message}
                                showErrorModal={showModal.code ? true : false}
                            />
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal>
        </section>
    )
}

export default EditTaskModal