import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { faCalendar, faCalendarDays, faCircleUser } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Form, Row, InputGroup } from 'react-bootstrap'
import { faArrowDownWideShort, faSearch, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import AddPeopleModal from '../AddPeopleModal/AddPeopleModal';
import TaskDetailModal from '../TaskDetailModal/TaskDetailModal';
import { Context } from "../../../../../utils/context";
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
const BoardTab = ({ project_id }) => {
    const [modalShow, setModalShow] = useState(false);

    const [taskmodalShow, setTaskModalShow] = React.useState(false);

    const [showFilter, setShowFilter] = useState(false);

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const [status, setStatus] = useState('');

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case '1':
                return 'red';
            case '2':
                return 'blue';
            case '3':
                return 'green';
            default:
                return 'black';
        }
    };

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        watch,
        getValues,
        reset,
    } = useForm();

    const { getData, postData, IMG_URL, deleteData, Select2Data } = useContext(Context);
    const [data, setData] = useState();
    const [taskStatus, settaskStatus] = useState([]);
    const [selctedId, setSelectedId] = useState("");
    const getDataAll = async () => {
        const response = await getData(
            `/projectmanagement/project-summary/taskgroup/${project_id}`
        );
        await setData(response?.data || []);
        reset(response?.data)
    };

    console.log("getValue", getValues());


    const onSubmit = async (id, data) => {
        try {
            const sendData = new FormData();
            sendData.append("task_status_id", data);
            const response = await postData(`/projectmanagement/task/${id}`, sendData);
            console.log(response);
            if (response?.success) {
                await getDataAll();
            } else {

            }
        } catch (error) {
            console.log(error);
        }
    };

    const GetAllTAskStatus = async () => {
        const res = await getData("/masters/all-task-status");
        if (res?.success) {
            settaskStatus(await Select2Data(res?.data, "task_status_id"))
        }
    };
    useEffect(() => {
        getDataAll();
        GetAllTAskStatus();
    }, []);

    console.log("data", data);

    // useEffect(() => {
    //     console.log("this is id", selctedId);

    //     setTaskModalShow(true)
    // }, [selctedId]);


    return (
        <section className='boardtab' >
            <Row>
                {data?.map((statusGroup, index) => (
                    <Col lg={3} key={index}>
                        <div className='main-class-board'>
                            <div className='dark-brown'>
                                <p className='todo-task-txtt'>
                                    {statusGroup.task_status_name}{' '}
                                    <span className='count'>({statusGroup.tasks.length})</span>
                                </p>
                            </div>
                            <div className='sub-main'>
                                {statusGroup?.tasks?.map((task, taskIndex) => (
                                    <div className='brown-bggg' key={taskIndex}>
                                        <div className='d-flex justify-content-between'>
                                            <p onClick={() => {
                                                setSelectedId(task?.id);
                                                setTaskModalShow(true);
                                                // handleClick(task?.id);
                                            }}>{taskIndex + 1}. {task.name}</p>
                                            <div>
                                                {/* <Controller

                                                    name={`task_status_id_${task.id}`} // Unique name for each task
                                                    control={control}
                                                    defaultValue={task?.task_status_id} // Set the default value to the task's current status
                                                    render={({ field }) => (
                                                        <Select

                                                            {...field}
                                                            options={taskStatus}
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors.task_status_id
                                                                        ? "red"
                                                                        : baseStyles,
                                                                }),
                                                            }}
                                                            value={taskStatus.find(option => option.value === field.value)} // Ensure the select displays the correct status
                                                            onChange={(selectedOption) => {
                                                                // field.onChange(selectedOption.value); // Update the value
                                                                onSubmit(task.id, selectedOption.value); // Handle the change
                                                            }}
                                                        />
                                                    )}
                                                /> */}
                                                <select
                                                    // value={task?.task_status_id || ''}
                                                    onChange={(e) => onSubmit(task?.id, e.target.value)}
                                                    style={{
                                                        // color: getStatusColor(task?.task_status_id),
                                                        border: '1px solid #ccc',
                                                        background: 'transparent',
                                                        padding: '5px 10px',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        cursor: 'pointer',
                                                        borderRadius: '50px',
                                                    }}
                                                >

                                                    {taskStatus.map((priority) => (
                                                        <option
                                                            key={priority.value}
                                                            value={priority.value}
                                                            selected={statusGroup.task_status_name === priority.label} // This is incorrect in JSX
                                                        >
                                                            {priority.label}
                                                        </option>
                                                    ))}

                                                </select>

                                            </div>
                                        </div>
                                        <span><FontAwesomeIcon icon={faCalendarDays} className='me-2' />{task.due_date}</span>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <span className='pjp-txttt'>{task.task_id}</span>
                                            <span
                                                className='usernameee'
                                                onClick={() => {
                                                    setSelectedId(task?.id);
                                                    setTaskModalShow(true);
                                                    // handleClick(task?.id);
                                                }}
                                            >
                                                {task?.assign_to_id?.label}
                                            </span>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                ))}

            </Row>
            <TaskDetailModal
                show={taskmodalShow}
                onHide={() => setTaskModalShow(false)}
                id={selctedId}
            />


        </section>
    )
}

export default BoardTab