import React, { useContext } from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select';

import { Row, Col, Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import CommonSuccessModal from "../../CommonSuccessModal/CommonSuccessModal";
import ModalSave from "../../saveModal/ModalSave";

const options = [
    { value: 'Rakhi Deshmukh', label: 'Rakhi Deshmukh' },
    { value: 'Smita Lad', label: 'Smita Lad' },
    { value: 'John Due', label: 'John Due' },
];
const ProjectTeamEdit = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [returnMessage, setReturnMessage] = useState(false);
    const [showModal, setShowModal] = useState({
        code: 0,
        message: "",
    });

    const id = props?.id;

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        watch,
        getValues,
        reset,
    } = useForm();

    const { getData, postData, Select2Data } = useContext(Context);

    const [projects, setProjects] = useState([]);
    const [tls, setTls] = useState([]);
    const [users, setUsers] = useState([]);

    const onSubmit = async (data) => {
        try {
            console.log(data);
            const sendData = new FormData();
            // sendData.append("name", data?.name);
            sendData.append("team_leader_id", data?.team_leader_id?.value);
            // sendData.append("project_id", data?.project_id?.value);
            data.user_id.forEach((user) => {
                sendData.append("user_id", user.value);
            });
            const response = await postData(`/projectmanagement/project/${id}`, sendData);
            console.log(response);
            if (response?.success) {
                setShowModal({ code: response?.code, message: response.message });
                setTimeout(() => {
                    setShowModal(0);
                    props.onHide();
                    props.getDataAll();
                    reset();
                }, 3000);
            } else {
                setShowModal({ code: response?.code, message: response.message });
                setTimeout(() => {
                    setShowModal(0);
                    props.onHide();
                    props.getDataAll();
                    reset();
                }, 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const GetAllProjects = async () => {
        const res = await getData("/project_management/allproject");
        if (res?.success) {
            setProjects(await Select2Data(res?.data, "project_id"))
        }
    };

    const GetAllEmployees = async () => {
        const res = await getData("/masters/allemployees");
        if (res?.success) {
            setUsers(await Select2Data(res?.data, "user_id"));
        }
    };

    const GetAllTeamLeaders = async () => {
        const res = await getData("/masters/allteamleaders");
        if (res?.success) {
            setTls(await Select2Data(res?.data, "team_leader_id"));
        }
    };

    const GetEditData = async () => {
        const res = await getData(`/projectmanagement/project/${id}`);
        if (res?.success) {
            reset(res?.data);
            reset({
                ...res?.data,
                user_id: res?.data?.team_members,
                // other fields if needed
            });

        }
    };

    console.log(":datadata", getValues());

    useEffect(() => {
        GetAllProjects();
        GetAllEmployees();
        GetAllTeamLeaders()
    }, []);

    useEffect(() => {
        console.log("id", id);

        GetEditData();
    }, [id]);
    return (
        <section >
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className='project-client-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Team
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Project Name</Form.Label>
                                    <Form.Control
                                        className={classNames("", {
                                            "is-invalid": errors?.name,
                                            "is-valid": getValues("name"),
                                        })}
                                        disabled
                                        type="text"
                                        {...register("name", {
                                            required: "Name is required",
                                        })}
                                        placeholder="Name"
                                        onKeyDown={(event) => {
                                            const { key } = event;
                                            if (!/^[A-Za-z\s]+$/.test(key) && key !== "Backspace" && key !== "Tab") {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors.name && (
                                        <span className="text-danger">
                                            {errors.name.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            {/* <Col lg={6}></Col> */}
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Team Lead</Form.Label>
                                    <Controller
                                        name="team_leader_id" // name of the field
                                        {...register("team_leader_id", {
                                            required: "Team Leader is required",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.team_leader_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={tls}
                                            />
                                        )}
                                    />

                                    {errors.team_leader_id && (
                                        <span className="text-danger">
                                            {errors.team_leader_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Team members</Form.Label>
                                    <Controller
                                        name="user_id" // name of the field
                                        {...register("user_id", {
                                            required: "Team Member is required",
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.user_id,
                                            "is-valid": getValues("user_id"),
                                        })}
                                        control={control}

                                        render={({ field }) => (
                                            <Select
                                                isMulti
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.user_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={users}
                                            />
                                        )}
                                    />

                                    {errors.user_id && (
                                        <span className="text-danger">
                                            {errors.user_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            {/* <Col lg={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Project Name</Form.Label>
                                    <Controller
                                        name="project_id" // name of the field
                                        {...register("project_id", {
                                            required: "Project is required",
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.project_id,
                                            "is-valid": getValues("project_id"),
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.project_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={projects}
                                            />
                                        )}
                                    />

                                    {errors.project_id && (
                                        <span className="text-danger">
                                            {errors.project_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col> */}
                        </Row>
                        <Modal.Footer>
                            <div className='mx-auto'>
                                <button
                                    // onClick={props.onHide}
                                    type="submit"
                                    className='submit-btn me-2'
                                    onClick={() => setModalShow(true)}
                                >
                                    <FontAwesomeIcon icon={faFloppyDisk} /> Save
                                </button>
                                <button onClick={props.onHide} className='submit-btn me-2'>
                                    <FontAwesomeIcon icon={faXmark} /> Cancel
                                </button>
                            </div>
                            <ModalSave
                                message={showModal.message}
                                showErrorModal={showModal.code ? true : false}
                            />
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal>
        </section>
    )
}

export default ProjectTeamEdit