import React, { useContext } from "react";
import { useState, useEffect } from "react";
import ProjectTaskTable from '../../../ProjectTask/ProjectTaskTable.js'
import { Context } from "../../../../../utils/context";

const ListTab = ({ project_id, tasks, getDataAll }) => {
  return (
    <section>
      <ProjectTaskTable data={tasks} getDataAll={getDataAll} />
    </section>
  )
}

export default ListTab